var map = "";
var marker = "";
if (typeof google != 'undefined') {
    var directionsDisplay = new google.maps.DirectionsRenderer();;
    var geocoder = new google.maps.Geocoder();
}
var complementos = "";
var direccion;
var direccion_sin_complemtos;

function eliminar_video(id, e) {
    e.preventDefault();
    e.stopPropagation();

    if (id) {
        if (confirm('¿Seguro que desea eliminar este video?')) {
            $.get('/admin/delete_url_embed/' + id)
                .done(function(data) {
                    var parent = $(e.target).closest('.embed-video');
                    parent.find('.preview').hide();
                    parent.find('.input-group input').val('');
                });
        }
    }
}

jQuery(document).ready(function($) {

    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    function cargarEmbedPreview(loader, url) {
        if (url !== "") {
            if (url === "") {
                $(loader).hide();
                $(loader).html('');
            } else {
                $(loader).show();
                $(loader).html('<iframe width="100%" height="315" src="' + url + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
            }
        } else {
            $(loader).hide();
            $(loader).html('');
        }
    }

    function validateInputURL(e, $thisInputGroup, patron, preview) {
        e.preventDefault();
        e.stopPropagation();

        var url = e.target.value;

        if (youtube_parser(url) && youtube_parser(url).length === 11) {
            url = 'https://www.youtube.com/embed/' + youtube_parser(url);
        }

        if (url && url !== '') {
            var validacion = (url.match(patron)) ? RegExp.$1 : false;

            if (validacion) {
                $thisInputGroup.closest('.form-group').removeClass('has-error');
                $thisInputGroup.closest('.form-group').find('.help-block').hide();

                $thisInputGroup.closest('.form-group').find('input').val(url);
                cargarEmbedPreview(preview, url);
            } else {
                $thisInputGroup.closest('.form-group').addClass('has-error');
                $thisInputGroup.closest('.form-group').find('.help-block').show();

                $(preview).html('');
            }
        } else {
            $thisInputGroup.closest('.form-group').removeClass('has-error');
            $thisInputGroup.closest('.form-group').find('.help-block').hide();
            $(preview).html('');
        }
    }

    var $inputURLMatterport = $('input#mmatterporturl');
    var previewMatterportID = $('#previewImgMatterport');
    var $inputURLYoutube = $('input#mvideosurl');
    var previewYoutubeID = $('#previewImgYoutube');

    $inputURLMatterport.closest('.form-group').find('.help-block').hide();
    $inputURLYoutube.closest('.form-group').find('.help-block').hide();

    $inputURLMatterport
        .keypress(function(e) {
            var $thisInputGroup = $(this).closest('div.input-group');

            $thisInputGroup.closest('.form-group').removeClass('has-error');
            $thisInputGroup.closest('.form-group').find('.help-block').hide();

            if (e.keyCode === 13) {
                var patron = /^(?:https?:\/\/)?(?:my\.)?(?:matter\.port\/|matterport\.com\/(?:show\/\?m=))((\w|-){11})(?:\S+)?$/;

                return validateInputURL(e, $thisInputGroup, patron, previewMatterportID);
            }
        })
        .change(function(e) {
            var $thisInputGroup = $(this).closest('div.input-group');
            var patron = /^(?:https?:\/\/)?(?:my\.)?(?:matter\.port\/|matterport\.com\/(?:show\/\?m=))((\w|-){11})(?:\S+)?$/;

            $thisInputGroup.closest('.form-group').removeClass('has-error');
            $thisInputGroup.closest('.form-group').find('.help-block').hide();

            return validateInputURL(e, $thisInputGroup, patron, previewMatterportID);
        })
        .blur(function(e) {
            var $thisInputGroup = $(this).closest('div.input-group');
            var patron = /^(?:https?:\/\/)?(?:my\.)?(?:matter\.port\/|matterport\.com\/(?:show\/\?m=))((\w|-){11})(?:\S+)?$/;

            $thisInputGroup.closest('.form-group').removeClass('has-error');
            $thisInputGroup.closest('.form-group').find('.help-block').hide();

            return validateInputURL(e, $thisInputGroup, patron, previewMatterportID);
        });

    $inputURLYoutube
        .keypress(function(e) {
            var $thisInputGroup = $(this).closest('div.input-group');

            $thisInputGroup.closest('.form-group').removeClass('has-error');
            $thisInputGroup.closest('.form-group').find('.help-block').hide();

            if (e.keyCode === 13) {
                var patron = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

                return validateInputURL(e, $thisInputGroup, patron, previewYoutubeID);
            }
        })
        .change(function(e) {
            var $thisInputGroup = $(this).closest('div.input-group');
            var patron = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

            $thisInputGroup.closest('.form-group').removeClass('has-error');
            $thisInputGroup.closest('.form-group').find('.help-block').hide();

            return validateInputURL(e, $thisInputGroup, patron, previewYoutubeID);
        })
        .blur(function(e) {
            var $thisInputGroup = $(this).closest('div.input-group');
            var patron = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

            $thisInputGroup.closest('.form-group').removeClass('has-error');
            $thisInputGroup.closest('.form-group').find('.help-block').hide();

            return validateInputURL(e, $thisInputGroup, patron, previewYoutubeID);
        });

    if ($('.pagoKushki').length > 0) {
        var kushki = new KushkiCheckout({
            form: "kushki-pay-form",
            merchant_id: "10000002711545483671150603246160",
            amount: document.getElementById("valueT").value, // total amount
            currency: "COP", // Currency code, by default "USD"
            is_subscription: false // True if it is a subscription (recurring payment); False, otherwise.
        });
    }

    var xAutosubmit = function() {
        $(".espacio-input").on("change", function() {
            var val = $(this).val();

            if (val <= 0 || val > 9999) {
                alert('Valor no válido');
                $(this).val('');
                $(this).focus();
            }

        });

        $(document).on("change", "#areapb", function() {
            var c_area = $("#areapb").val();
            var propertyTypeId = $.trim($("#tipox").val());
            var localProperty = propertyTypeId === "16";
            var minimumArea = localProperty ? 2 : 0;
            var invalidArea = localProperty ?
                parseInt(c_area) < minimumArea :
                parseInt(c_area) <= minimumArea;       
            if (c_area == undefined || c_area == 0 || c_area == "" || invalidArea || isNaN(c_area)) {
                cleanwarinings();
                $("#areapb").css("border", "1px solid red");
                showTitleInvalidAreaPropertyLocal(minimumArea, localProperty);
                goToByScroll("areapb");
            } else {
                cleanwarinings();
            }
        });

        $(document).on("change", "#infobas_area_bodega", function() {
            var areabodega = $("input[type=number][name=infobas_area_bodega]").val();
            if ((areabodega == undefined || areabodega == 0 || areabodega == "" || parseInt(areabodega) <= 0 || isNaN(areabodega)) && ($("#infobas_area_bodega").length)) {
                cleanwarinings();
                $("#infobas_area_bodega").css("border", "1px solid red");
                goToByScroll("infobas_area_bodega");
            } else {
                cleanwarinings();
                $("#infobas_area_bodega").css("border", "1px solid #ebebeb");
            }
        });

        $(document).on("change", "#infobas_area_lote", function() {
            var arealote = $("input[type=number][name=infobas_area_lote]").val();
            if ((arealote == undefined || arealote == 0 || arealote == "" || parseInt(arealote) <= 0 || isNaN(arealote)) && ($("#infobas_area_lote").length)) {
                cleanwarinings();
                $("#infobas_area_lote").css("border", "1px solid red");
                goToByScroll("infobas_area_lote");
            } else {
                cleanwarinings();
                $("#infobas_area_lote").css("border", "1px solid #ebebeb");
            }
        });

        $(document).on("change", "#pventa", function() {
            var tipotran = $("select[name=infobas_estado_transaccion]").val();
            var pventa = $("#pventa").val();
            if (tipotran == "1") {
                canon = "1";
            } else if (tipotran == "2") {
                pventa = "1";
            }
            if (pventa == "" || pventa == "$0.00" || pventa == "$ 0" || pventa == "$ ") {
                cleanwarinings();
                $("#pventa").css("border", "1px solid red");
                $("#pventa").focus();
                goToByScroll("pventa");
            } else if (parseFloat(pventa.replace(/[$,]/g, "")) <= 10000000 && tipotran == 1 || tipotran == 3 && parseFloat(pventa.replace(/[$,]/g, "")) <= 10000000) {
                cleanwarinings();
                $("#pventa").css("border", "1px solid red");
                $("#pventa").focus();
                if ($(".errorVenta").length == 0) {
                    $("#pventa").after("<label style='color: red;font-size: 1.1em;margin: .5em 0;' class='errorVenta'>Ingresa un monto de mas de $10,000,000</label>");
                }
                goToByScroll("pventa");
            } else {
                cleanwarinings();
            }
        });

        $(document).on("change", "#canon", function() {
            var tipotran = $("select[name=infobas_estado_transaccion]").val();
            var canon = $("#canon").val();
            if (tipotran == "1") {
                canon = "1";
            } else if (tipotran == "2") {
                pventa = "1";
            }
            if (canon == "" || canon == "$0.00" || canon == "$ 0" || canon == "$ ") {
                cleanwarinings();
                $("#canon").css("border", "1px solid red");
                $("#canon").focus();
                goToByScroll("canon");
            } else if (parseFloat(canon.replace(/[$,]/g, "")) <= 100000 && tipotran == 2 || tipotran == 3 && parseFloat(canon.replace(/[$,]/g, "")) <= 100000) {
                cleanwarinings();
                $("#canon").css("border", "1px solid red");
                $("#canon").focus();
                if ($(".errorCanon").length == 0) {
                    $("#canon").after("<label style='color: red;font-size: 1.1em;margin: .5em 0;' class='errorCanon'>Ingresa un monto de mas de $100,000</label>");
                }
                goToByScroll("canon");
            } else {
                cleanwarinings();
            }
        });

        $("#tipox").change(function() {
            var $form = $(this).parents("form");
            var data = $form.serialize();
            $(".error").hide();
            sendFormInfo(data, $form, true);
        });

        $(document).on("change", ".auto_submit_item", function() {
            var $form = $(this).parents("form");
            var data = $form.serialize();
            $(".error").hide();
            sendFormInfo(data, $form, false);
        });

        function sendFormInfo(data, $form, reloadForm) {
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $("input[name=_token]").val()
                },
                url: $form.attr('action'),
                type: 'PUT',
                data: data,
                success: function(response) {
                    if (response == "infobas_error_codigo") {
                        $("#txt_error_codigo").text("Este codigo ya se encuentra registrado");
                        $("#input_error_codigo").css("border-color", "red");
                    } else {
                        $("#txt_error_codigo").text("Código propio del inmueble");
                        $("#input_error_codigo").css("border-color", "#cccccc");
                    }
                    if (reloadForm) {
                        location.reload();
                    }
                }
            });
        }
    }

    Number.prototype.formatMoney = function(c, d, t) {
        var n = this,
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d == undefined ? "." : d,
            t = t == undefined ? "," : t,
            s = n < 0 ? "-" : "",
            i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
            j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };

    if ($("#cotizacion").length) {
        $(".enviar_cotizacion").on("click", function(e) {
            e.preventDefault();
            var formname = $("#cotizacion").find(".coti_formname").val();
            var id_inmueble = $("#cotizacion").find(".coti_id_inmueble").val();
            var token = $("#cotizacion").find('input[name="_token"]').val();
            var data = combosinfo;
            var thisx = this;
            var combos = $(".cotizacion_combo").map(function() {
                if (this.checked) {
                    return this.attributes["data-combo"].value;
                }
            })

            var combosArray = [];
            combos.map(function() { combosArray.push(this) });
            var totalCoti = $("#cotizacion tfoot #total_cotizacion").attr("data-total-bruto");
            var iva = $("#cotizacion tfoot #total_iva").attr("data-total-bruto");
            var subtotal = $("#cotizacion tfoot #total_sub").attr("data-total-bruto");
            var totalCoti = $("#cotizacion tfoot #total_cotizacion").attr("data-total-bruto");
            $(this).attr("disabled", "disabled");
            $("#modaldetalle .flexmodal .modal-forms h3").html("Guardando");
            $("#modaldetalle").stop().fadeIn();
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': token
                },
                url: $("#cotizacion").attr('data-action'),
                type: 'POST',
                data: ({ formname: formname, id_inmueble: id_inmueble, servicios: data, combos: combosArray, total: totalCoti, iva: iva, subtotal: subtotal }),
                success: function(response) {
                    //...
                    var $form = $(thisx).parents("form");
                    e.preventDefault();
                    $.get(
                        $form.attr('action'),
                        function(data) {
                            $(this).attr("disabled", "disabled");
                            $("#modaldetalle .flexmodal .modal-forms h3").html(data);
                            $("#modaldetalle").stop().fadeIn();
                        }
                    );
                }
            });


        })
    }
    if ($(".login-form").length) {
        $("#loginIn").on("click", function(e) {
            e.preventDefault();
            var $form = $(this).parents("form");
            var data = $form.serialize();
            var method = $form.attr('method');
            $.ajax({
                url: $form.attr('action'),
                type: method,
                data: data,
                success: function(response) {

                    if (response == "superadmin" || response == "admin" || response == "asistencia") {
                        $form.find(".alert-success").removeClass("hidden");
                        $form.find(".alert-success").show();
                        location.reload();
                    } else {
                        $form.find(".alert-danger").removeClass("hidden");
                        $form.find(".alert-danger").html(response);
                        $form.find(".alert-danger").show();
                        setTimeout(function() { $form.find(".alert-danger").fadeOut(); }, 3000);
                    }
                },
                error: function(response) {
                    response = JSON.parse(response.responseText);
                    if (response.email != undefined && response.password != undefined) {
                        $form.find(".alert-danger").html(response.email + "<br>" + response.password);
                    } else if (response.email != undefined) {
                        $form.find(".alert-danger").html(response.email);
                    } else if (response.password != undefined) {
                        $form.find(".alert-danger").html(response.password);
                    }
                    $form.find(".alert-danger").removeClass("hidden");
                    $form.find(".alert-danger").show();
                    setTimeout(function() { $form.find(".alert-danger").fadeOut(); }, 3000);
                }
            });
        })
    }

    function sendPublishProperty() {
        $.ajax({
            headers: { 'X-CSRF-TOKEN': $("input[name=_token]").val() },
            url: $("#inmuebleUpdate").attr('data-action'),
            type: 'PUT',
            data: ({ formname: 'publicar' }),
            success: function(response) {
                $("#modaldetalle .flexmodal .modal-forms h3").html(response);
                $("#modaldetalle .flexmodal .modal-forms a").show();
                $("#modaldetalle").stop().fadeIn();
                if (response == 'Inmueble Publicado') {
                    location.reload();
                }
            },
            error: function(response) {
                $("#modaldetalle .flexmodal .modal-forms h3").html("Ocurrió un error");
                $("#modaldetalle").stop().fadeIn();
            }
        });
    }

    function validateValueInputCheckboxTypeParking(parqueaderos) {
        const parkings = parqueaderos - 1;

        let ilargo = $("input[type=number][name=p_1_largo]").val();
        ilargo = parseFloat(ilargo ? ilargo : 0);
        let ialto = $("input[type=number][name=p_1_alto]").val();
        ialto = parseFloat(ialto ? ialto : 0);
        let iancho = $("input[type=number][name=p_1_ancho]").val();
        iancho = parseFloat(iancho ? iancho : 0);
        const i = ilargo * ialto * iancho;

        let slargo = $("input[type=number][name=p_2_largo]").val();
        slargo = parseFloat(slargo ? slargo : 0);
        let salto = $("input[type=number][name=p_2_alto]").val();
        salto = parseFloat(salto ? salto : 0);
        let sancho = $("input[type=number][name=p_2_ancho]").val();
        sancho = parseFloat(sancho ? sancho : 0);
        const s = slargo * salto * sancho;

        let clargo = $("input[type=number][name=p_3_largo]").val();
        clargo = parseFloat(clargo ? clargo : 0);
        let calto = $("input[type=number][name=p_3_alto]").val();
        calto = parseFloat(calto ? calto : 0);
        let cancho = $("input[type=number][name=p_3_ancho]").val();
        cancho = parseFloat(cancho ? cancho : 0);
        const c = clargo * calto * cancho;

        let dlargo = $("input[type=number][name=p_4_largo]").val();
        dlargo = parseFloat(dlargo ? dlargo : 0);
        let dalto = $("input[type=number][name=p_4_alto]").val();
        dalto = parseFloat(dalto ? dalto : 0);
        let dancho = $("input[type=number][name=p_4_ancho]").val();
        dancho = parseFloat(dancho ? dancho : 0);
        const d = dlargo * dalto * dancho;

        return parkings === [i, s, c, d].filter(v => v != 0).length;
    }

    function identificarSiEsEdificioOrLote() {
        var tipo_inm = $("#tipox option:selected").text();
        return tipo_inm == 'Lote' || tipo_inm == 'Edificio';
    }

    // Identifica si el inmueble es de tipo depósito
    function identifyingWarehouse() {
        var propertyType = $("#tipox option:selected").text();
        return propertyType == 'Depósito';
    }

    $("#btn-publicar").on("click", function(e) {
        e.stopPropagation();
        e.preventDefault();

        cleanwarinings();

        var validacion_espacios_fisicos = [];

        for (var espacio_fisico of document.getElementsByName('espacio_media')) {
            var text = $(espacio_fisico).parent().find('.select2-selection__rendered').text();

            $(espacio_fisico).find('option').each(function(i, elem) {
                if (elem.text === text) {
                    if (elem.value > 0) {
                        validacion_espacios_fisicos.push(true);
                    } else {
                        validacion_espacios_fisicos.push(false);
                    }
                }
            });
        }
        validacion_espacios_fisicos = validacion_espacios_fisicos.filter(function(item) {
            return item === false;
        });

        var areabodega = "indeterminada";
        var arealote = "indeterminada";
        var banos = "indeterminada";
        var habitaciones = "indeterminada";
        var parqueaderos = "indeterminada";
        var acontronstruccionb = "indeterminada";
        var areapb = "indeterminada"
        var tipotran = $("select[name=infobas_estado_transaccion]").val();
        var type_parqueaderos = "indeterminada";

        if ($("input[type=radio][name=infobas_banos]").length > 0) {
            banos = $("input[type=radio][name=infobas_banos]:checked").val();
        }
        if ($("input[type=radio][name=infobas_habitaciones]").length > 0) {
            habitaciones = $('input[type=radio][name=infobas_habitaciones]:checked').val();
        }
        if ($("input[type=radio][name=infobas_parqueaderos]").length > 0) {
            parqueaderos = $('input[type=radio][name=infobas_parqueaderos]:checked').val();
        }
        if ($("input[type=checkbox][name='infobas_tparqueaderos[]']").length > 0) {
            type_parqueaderos = $("input[type=checkbox][name='infobas_tparqueaderos[]']:checked").val();
        }
        if ($("input[type=text][name=infobas_antiguedadinmu]").length > 0) {
            acontronstruccionb = $("input[type=text][name=infobas_antiguedadinmu]").val();
        }
        if ($("input[type=text][name=infobas_aprivada]").length > 0) {
            areapb = $("input[type=text][name=infobas_aprivada]").val();

        }
        if ($("input[type=number][name=infobas_area_bodega]").length > 0) {
            areabodega = $("input[type=number][name=infobas_area_bodega]").val();

        }
        if ($("input[type=number][name=infobas_area_lote]").length > 0) {
            arealote = $("input[type=number][name=infobas_area_lote]").val();

        }
        //solo para el formualario basica.blade.php
        if ($("input[type=text][name=infobas_aconstruida]").length > 0) {
            areapb = $("input[type=text][name=infobas_aconstruida]").val();
        }

        if (validacion_espacios_fisicos.length > 0) {
            $("#modaldetalle .flexmodal .modal-forms h3").html("Debes seleccionar los espacios físicos de las fotos proporcionadas");
            $("#modaldetalle .flexmodal .modal-forms a").addClass("cerrarBtn");
            $("#modaldetalle").stop().slideDown();
            cerrarModales();
            return false;
        }

        if ($('.item-image').length > 0 && $('.fotoPortada:checked').length <= 0) {
            $("#modaldetalle .flexmodal .modal-forms h3").html("Debes seleccionar una foto de portada");
            $("#modaldetalle .flexmodal .modal-forms a").addClass("cerrarBtn");
            $("#modaldetalle").stop().slideDown();
            cerrarModales();
            return false;
        }

        var $form = $(this).parents("form");
        var data = $form.serialize();
        var self = $(this);
        var name = $(this).attr("name");
        var html = "";
        var coments = $("#InfoValidation").val();
        var pventa = $("#pventa").val();
        var canon = $("#canon").val();
        var observacion = $("#observaciones").val();
        var esEdificiooLote = identificarSiEsEdificioOrLote();
        var isWarehouse = identifyingWarehouse();

        if (observacion !== "WEB_SERVICE") {
            observacion = 'otro';
        } else {
            observacion = 'web_service';
        }

        var propertyTypeId = $.trim($("#tipox").val());
        var localProperty = propertyTypeId === "16";
        var minimumArea = localProperty ? 2 : 0;
        var invalidArea = localProperty ?
            parseInt(areapb) < minimumArea :
            parseInt(areapb) <= minimumArea;

        switch (observacion) {
            case 'otro':
                if (tipotran == "1") {
                    canon = "1";
                } else if (tipotran == "2") {
                    pventa = "1";
                }
                if (coments.length == 0) {
                    cleanwarinings();
                    $("#InfoValidation").css("border", "1px solid red");
                    $("#InfoValidation").focus();
                    cont = true;
                } else if (pventa == "" || pventa == "$0.00" || pventa == "$ 0" || pventa == "$ ") {
                    cleanwarinings();
                    $("#pventa").css("border", "1px solid red");
                    $("#pventa").focus();
                } else if (parseFloat(pventa.replace(/[$,]/g, "")) <= 10000000 && tipotran == 1 || tipotran == 3 && parseFloat(pventa.replace(/[$,]/g, "")) <= 10000000) {
                    cleanwarinings();
                    $("#pventa").css("border", "1px solid red");
                    $("#pventa").focus();
                    if ($(".errorVenta").length == 0) {
                        $("#pventa").after("<label style='color: red;font-size: 1.1em;margin: .5em 0;' class='errorVenta'>Ingresa un monto de mas de $10,000,000</label>");
                    }
                } else if (canon == "" || canon == "$0.00" || canon == "$ 0" || canon == "$ ") {
                    cleanwarinings();
                    $("#canon").css("border", "1px solid red");
                    $("#canon").focus();
                } else if (parseFloat(canon.replace(/[$,]/g, "")) <= 100000 && tipotran == 2 || tipotran == 3 && parseFloat(canon.replace(/[$,]/g, "")) <= 100000) {
                    cleanwarinings();
                    $("#canon").css("border", "1px solid red");
                    $("#canon").focus();
                    if ($(".errorCanon").length == 0) {
                        $("#canon").after("<label style='color: red;font-size: 1.1em;margin: .5em 0;' class='errorCanon'>Ingresa un monto de mas de $100,000</label>");
                    }
                } else if (!isWarehouse && habitaciones == undefined) {
                    cleanwarinings();
                    $("#habitacionesb").css("border", "1px solid red");
                    goToByScroll("habitacionesb");
                } else if (!isWarehouse && banos == undefined) {
                    cleanwarinings();

                    $("#banosb").css("border", "1px solid red");
                    goToByScroll("banosb");

                } else if (!(esEdificiooLote || isWarehouse) && parqueaderos == undefined) {
                    cleanwarinings();

                    $("#parqueaderosb").css("border", "1px solid red");
                    goToByScroll("parqueaderosb");
                } else if (!esEdificiooLote && type_parqueaderos == undefined && parqueaderos > 1) {
                    cleanwarinings();
                    alert(`Debe elegir un tipo de parqueadero`);
                    $("#type_parqueaderos").css("border", "1px solid red");
                    goToByScroll("type_parqueaderos");
                } else if (areapb == undefined || invalidArea || areapb == "") {
                    cleanwarinings();
                    $("#areapb").css("border", "1px solid red");
                    showTitleInvalidAreaPropertyLocal(minimumArea, localProperty);
                    goToByScroll("areapb");
                } else if ((areabodega == undefined || areabodega == 0 || areabodega == "" || parseInt(areabodega) <= 0 || isNaN(areabodega)) && ($("#infobas_area_bodega").length)) {
                    cleanwarinings();
                    $("#infobas_area_bodega").css("border", "1px solid red");
                    goToByScroll("infobas_area_bodega");
                } else if ((arealote == undefined || arealote == 0 || arealote == "" || parseInt(arealote) <= 0 || isNaN(arealote)) && ($("#infobas_area_lote").length)) {
                    cleanwarinings();
                    $("#infobas_area_lote").css("border", "1px solid red");
                    goToByScroll("infobas_area_lote");
                } else if (acontronstruccionb == undefined) {
                    cleanwarinings();
                    $("#acontronstruccionb").css("border", "1px solid red");
                    goToByScroll("acontronstruccionb");
                } else if (tipotran == "seleccione") {
                    cleanwarinings();

                    $("#s2id_tipotran").css("border", "1px solid red");
                    $("#tipotran").css("border", "1px solid red");
                    goToByScroll("s2id_tipotran");

                } else {
                    cleanwarinings();
                    $("#modaldetalle .flexmodal .modal-forms h3").html("Cargando...");
                    $("#modaldetalle .flexmodal .modal-forms a").hide();
                    $("#modaldetalle .flexmodal .modal-forms a").addClass("cerrarBtn");
                    cerrarModales();
                    $("#modaldetalle").stop().fadeIn();
                    sendPublishProperty();
                    setTimeout(function() {
                        sendPublishProperty();
                    }, 9000);
                }
                break;
            case 'web_service':
                if (tipotran == "1") {
                    canon = "1";
                } else if (tipotran == "2") {
                    pventa = "1";
                }
                if (coments.length == 0) {
                    cleanwarinings();
                    $("#InfoValidation").css("border", "1px solid red");
                    $("#InfoValidation").focus();
                    cont = true;
                } else if (habitaciones == undefined) {
                    cleanwarinings();
                    $("#habitacionesb").css("border", "1px solid red");
                    goToByScroll("habitacionesb");
                } else if (banos == undefined) {
                    cleanwarinings();

                    $("#banosb").css("border", "1px solid red");
                    goToByScroll("banosb");

                } else if (!(esEdificiooLote || isWarehouse) && parqueaderos == undefined) {
                    cleanwarinings();

                    $("#parqueaderosb").css("border", "1px solid red");
                    goToByScroll("parqueaderosb");
                } else if (!esEdificiooLote && type_parqueaderos == undefined && parqueaderos > 1) {
                    cleanwarinings();
                    alert(`Debe elegir un tipo de parqueadero`);
                    $("#type_parqueaderos").css("border", "1px solid red");
                    goToByScroll("type_parqueaderos");
                } else if (areapb == undefined || invalidArea || areapb == "") {
                    cleanwarinings();
                    $("#areapb").css("border", "1px solid red");
                    showTitleInvalidAreaPropertyLocal(minimumArea, localProperty);
                    goToByScroll("areapb");
                } else if (tipotran == "seleccione") {
                    cleanwarinings();

                    $("#s2id_tipotran").css("border", "1px solid red");
                    $("#tipotran").css("border", "1px solid red");
                    goToByScroll("s2id_tipotran");

                } else {
                    cleanwarinings();
                    $("#modaldetalle .flexmodal .modal-forms h3").html("Cargando...");
                    $("#modaldetalle .flexmodal .modal-forms a").hide();
                    $("#modaldetalle .flexmodal .modal-forms a").addClass("cerrarBtn");
                    cerrarModales();
                    $("#modaldetalle").stop().fadeIn();
                    sendPublishProperty();
                    setTimeout(function() {
                        sendPublishProperty();
                    }, 9000);
                }
                break;
        }
    });

    if ($("#cotizacion").length) {
        $(".currency").map(function() {
            var valor = $(this).val();
            valor = parseFloat(valor);
            valor = (valor).formatMoney(2, '.', ',');
            $(this).val("$" + valor)
            $(this).css("text-align", "right");
        })
        $(".currency").on("focus", function() {
            var valor = $(this).val();
            valor = valor.replace(/,/g, "");
            valor = valor.replace("$", "");
            valor = parseFloat(valor);
            $(this).val(valor)
        })
        $(".currency").on("focusout", function() {
            var valor = $(this).val();
            valor = valor.replace(/,/g, "");
            valor = valor.replace("$", "");
            valor = parseFloat(valor);
            valor = (valor).formatMoney(2, '.', ',');
            $(this).val("$" + valor)
        })
        $(".currency").on("change", function(e) {
            //Lo cambia
            var valor = $(this).val();
            $(this).val("")
            valor = valor.replace(/,/g, "");
            valor = valor.replace("$", "");
            valor = parseFloat(valor);
            valor = (valor).formatMoney(2, '.', ',');
            $(this).val("$" + valor)
        })

        //Logica modal empezar publicacion 
        $(".empezar_publicacion").on("click", function(e) {
                e.preventDefault();
                var token = $("#cotizacion").find('input[name="_token"]').val();
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: $("#inmuebleUpdate").attr('data-action'),
                    type: 'PUT',
                    data: ({ formname: 'empezar' }),
                    success: function(response) {
                        //...
                        $("#modalInit").stop().fadeOut();

                    },
                    error: function(response) {
                        location.reload();
                    }
                });
            })
            //
            //Logica modal empezar publicacion 
        $(".cancelar_publicacion").on("click", function(e) {
                e.preventDefault();
                var token = $("#cotizacion").find('input[name="_token"]').val();
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: $("#inmuebleUpdate").attr('data-action'),
                    type: 'PUT',
                    data: ({ formname: 'cancelar' }),
                    success: function(response) {
                        //..
                        $("#modalInit").stop().fadeOut();
                        $("#modal-1").stop().fadeIn();

                    }
                });
            })
            //
            //Logica Cotizador

        $(".interesado_alistamiento").on("change", function() {
            if ($(this).val() == '1') {
                $(".cotizacionDiv").removeClass("hidden");
            } else {
                $(".cotizacionDiv").addClass("hidden");
            }

        });

        $(".cotizacion_combo").on("change", function() {
            let combo = this.attributes["data-combo"].value
            if ($(this)[0].checked) {
                let arrayPisos = $(".cotizacion_input").map(function(e) { if ($(this).attr('data-combo') == combo) { return this } })
                arrayPisos.map(function(e) {
                    var porcien = $("#cotizacion").find("input[name=pcien]").val();
                    var porinmo = $("#cotizacion").find("input[name=pinmo]").val();
                    porcien = parseFloat(porcien) / 100;
                    porinmo = parseFloat(porinmo) / 100;
                    var valor = $(this).attr("data-valor");
                    valorF = parseFloat(valor);
                    valorF = (valorF * porcien + valorF * porinmo) + valorF;

                    var valorF = (valorF).formatMoney(2, '.', ',');
                    var id = $(this).attr("data-id");
                    var factor = $(this).attr("data-factor");
                    var name = $(this).attr("data-name");
                    var tipo = $(this).attr("data-tipo");
                    var servicio = "";
                    if (tipo == 'estandar') {
                        servicio = "<tr class='combo-" + combo + "' data-update='0' id='" + id + "' data-estate='0'>" +
                            "<td>" + name + "</td>" +
                            "<td><input  class='form-control hidden variable' data-combox='" + combo + "' data-id='" + id + "'data-tarifa='" + valor + "'  type='number' data-factor='" + factor + "'></td>" +
                            "<td><textarea class='form-control notas'></textarea></td>" +
                            "<td class='valorF' data-combox='" + combo + "' data-bruto='" + valor + "'>$" + valorF + "</td>" +
                            "</tr>";
                    } else {
                        servicio = "<tr data-update='0' id='" + id + "' data-estate='0' class='combo-" + combo + "'>" +
                            "<td>" + name + "</td>" +
                            "<td><input class='form-control variable' data-id='" + id + "' data-combox='" + combo + "' type='number' data-tarifa='" + valor + "' data-factor='" + factor + "'></td>" +
                            "<td><textarea class='form-control notas'></textarea></td>" +
                            "<td class='valorF' data-combox='" + combo + "' data-bruto='0' >$0</td>" +
                            "</tr>";
                    }

                    $("#cotizacion tbody").append(servicio);
                    //$(".variable").inputmask({ 'alias': 'decimal' });

                })
                sumarCotizacion();

                $(".variable").keyup(function() {
                    var factor = $(this).attr("data-factor");
                    var area = $(this).val();
                    var tarifa = $(this).attr("data-tarifa");
                    var id = $(this).attr("data-id");

                    if (factor != '') {
                        factor = parseFloat(factor);
                        area = parseFloat(area);
                        tarifa = parseFloat(tarifa);
                        var porcien = $("#cotizacion").find("input[name=pcien]").val();
                        var porinmo = $("#cotizacion").find("input[name=pinmo]").val();
                        porcien = parseFloat(porcien) / 100;
                        porinmo = parseFloat(porinmo) / 100;
                        tarifa = (tarifa * porcien + tarifa * porinmo) + tarifa;
                        preTotal = area * tarifa * factor;
                        preTotal = Math.round(preTotal * 100) / 100;
                    } else if (id == 5) {
                        area = parseFloat(area);
                        tarifa = parseFloat(tarifa);
                        preTotal = area * tarifa * 2;
                        preTotal = Math.round(preTotal * 100) / 100;
                    } else {
                        area = parseFloat(area);
                        tarifa = parseFloat(tarifa);
                        preTotal = area * tarifa;
                        preTotal = Math.round(preTotal * 100) / 100;
                    }
                    $(this).parents("tr").find(".valorF").attr("data-bruto", preTotal);
                    preTotal = (preTotal).formatMoney(2, '.', ',');
                    $(this).parents("tr").find(".valorF").text('$' + preTotal);
                    sumarCotizacion();
                });

            } else {
                let arrayPisos = $(".cotizacion_input").map(function(e) { if ($(this).attr('data-combo') == combo) { return this } })
                $(".total-combo-" + combo).remove();
                arrayPisos.map(function(e) {
                    var id = $(this).attr("data-id");
                    var id_destroy = $("#" + id).attr("data-update");
                    var id_inmueble = $("#cotizacion").find(".coti_id_inmueble").val();
                    $("#" + id).remove();
                    var formname = $("#cotizacion").find(".coti_formname").val();
                    var token = $("#cotizacion").find('input[name="_token"]').val();
                    $.ajax({
                        headers: {
                            'X-CSRF-TOKEN': token
                        },
                        url: $("#cotizacion").attr('data-action'),
                        type: 'POST',
                        data: ({ formname: formname, id_destroy: id_destroy, destroy: '1', id_inmueble: id_inmueble }),
                        success: function(response) {}
                    });
                })
                sumarCotizacion();


            }
        })
        $(".variable").keyup(function() {
            var factor = $(this).attr("data-factor");
            var area = $(this).val();
            var tarifa = $(this).attr("data-tarifa");
            var id = $(this).attr("data-id");
            if (factor != '') {
                factor = parseFloat(factor);
                area = parseFloat(area);
                tarifa = parseFloat(tarifa);
                preTotal = area * tarifa * factor;
                preTotal = Math.round(preTotal * 100) / 100;
            } else if (id == 5) {
                area = parseFloat(area);
                tarifa = parseFloat(tarifa);
                preTotal = area * tarifa * 2;
                preTotal = Math.round(preTotal * 100) / 100;
            } else {
                area = parseFloat(area);
                tarifa = parseFloat(tarifa);
                preTotal = area * tarifa;
                preTotal = Math.round(preTotal * 100) / 100;
            }
            $(this).parents("tr").find(".valorF").attr("data-bruto", preTotal);
            preTotal = (preTotal).formatMoney(2, '.', ',');
            $(this).parents("tr").find(".valorF").text('$' + preTotal);
            sumarCotizacion();
        });
        if ($(".variable").length > 0) {
            function setNumbers() {
                //$(".variable").inputmask({ 'alias': 'decimal' });
                $("#cotizacion tbody tr").map(function() {
                    var newnumber = $(this).find(".valorF").text();
                    newnumber = parseFloat(newnumber);
                    newnumber = (newnumber).formatMoney(2, '.', ',');
                    $(this).find(".valorF").text('$' + newnumber);
                })
            }
            setNumbers();
        }
        var combosinfo = [];

        function sumarCotizacion() {
            combosinfo = []
            let combos = $(".cotizacion_combo").map(function() {
                if (this.checked) {
                    return this.attributes["data-combo"].value;
                }
            })
            let subtotales = combos.map(function() {

                let combo = this[0]
                let totalc = 0;
                let comboInfoItem = { id: combo, nombre: '', servicios: [], totalCombo: 0 }

                $("#cotizacion tbody tr").map(function() {


                    var estate = $(this).attr("data-estate");
                    var id_update = $(this).attr("data-update");
                    var idServicio = this.id;
                    var searchcombo = $(this).find(".valorF").attr("data-combox");
                    var notas = $(this).find(".notas").val();


                    if (searchcombo == combo) {
                        var valor = $(this).find(".valorF").attr("data-bruto");
                        if (isNaN(valor)) {
                            valor = 0;
                        }
                        valor = parseFloat(valor);
                        totalc += valor;
                        var area = $(this).find(".variable").val();
                        if (area == "") {
                            area = 0;
                        }
                        var nombre = this.children[0].innerText
                        var servicioInfo = { id: idServicio, nombre: nombre, total: valor, area: area, notas: notas, estate: estate, id_update: id_update };
                        comboInfoItem.servicios.push(servicioInfo);
                    }

                })
                comboInfoItem.totalCombo = totalc;
                totalc = (totalc).formatMoney(2, '.', ',');
                let comboName = $(".cotizacion_combo").map(function() { if (this.attributes["data-combo"].value == combo) { return this.value } })
                comboInfoItem.nombre = comboName[0];
                servicio = "<tr  class='total-combo-" + combo + " total-combo'>" +
                    "<td>" + comboName[0] + "</td>" +
                    "<td></td>" +
                    "<td></td>" +
                    "<td class='valorS'>$" + totalc + "</td>" +
                    "</tr>"
                if (!$(".total-combo-" + combo).length > 0) {
                    $(".combo-" + combo).last().after(servicio);
                } else {
                    $(".total-combo-" + combo).find(".valorS").html("$" + totalc);
                }
                combosinfo.push(comboInfoItem);
                return totalc;
            })
            let total = 0;
            $("#cotizacion tbody tr").map(function() {
                if ($(this).find(".valorF").attr("data-bruto")) {
                    var valor = $(this).find(".valorF").attr("data-bruto");
                    valor = parseFloat(valor);
                    if (isNaN(valor)) {
                        valor = 0;
                    }
                    total += valor;

                }
            })
            let iva = total * 0.19;
            let stotal = iva + total;
            $("#cotizacion tfoot #total_sub").attr("data-total-bruto", total);
            total = (total).formatMoney(2, '.', ',');
            $("#cotizacion tfoot #total_sub").text('$' + total);

            $("#cotizacion tfoot #total_iva").attr("data-total-bruto", iva);
            iva = (iva).formatMoney(2, '.', ',');
            $("#cotizacion tfoot #total_iva").text('$' + iva);

            $("#cotizacion tfoot #total_cotizacion").attr("data-total-bruto", stotal);
            stotal = (stotal).formatMoney(2, '.', ',');
            $("#cotizacion tfoot #total_cotizacion").text('$' + stotal);
        }
        $(".guardar_cotizacion").on("click", function(e) {
            sumarCotizacion();
            e.preventDefault();

            var formname = $("#cotizacion").find(".coti_formname").val();
            var emails = $(".cotizacionDiv").find(".emailscoti").val();
            var comentarios_cotizacion = $(".cotizacionDiv").find("#comentarios_cotizacion").val();
            var comentarios_preexistencias = $(".cotizacionDiv").find("#comentarios_preexistencias").val();


            var id_inmueble = $("#cotizacion").find(".coti_id_inmueble").val();
            var token = $("#cotizacion").find('input[name="_token"]').val();
            var data = combosinfo;
            var combos = $(".cotizacion_combo").map(function() {
                if (this.checked) {
                    return this.attributes["data-combo"].value;
                }
            })
            var comentarios_renovacion = $(".cotizacionDiv").find("#comentarios_renovacion").val();
            var propietario = document.getElementsByClassName('Ipropietario')[0].checked;
            var propietariox = {};
            if (propietario) {
                propietariox.nombre = document.getElementById('pnombrep').value;
                propietariox.cedula = document.getElementById('pcedulap').value;
                propietariox.telefono = document.getElementById('ptelefonop').value;
                propietariox.correo = document.getElementById('pcorreop').value;
            }
            var combosArray = [];
            combos.map(function() { combosArray.push(this) });
            var totalCoti = $("#cotizacion tfoot #total_cotizacion").attr("data-total-bruto");
            var iva = $("#cotizacion tfoot #total_iva").attr("data-total-bruto");
            var subtotal = $("#cotizacion tfoot #total_sub").attr("data-total-bruto");
            var status = this.attributes["data-status"].value;

            if (status == 'new') {
                comentarios_renovacion = 'Nueva cotización';
            }


            if (combos.length > 0) {
                if (totalCoti > 0) {

                    if (comentarios_renovacion.length > 0) {
                        $(this).attr("disabled", "disabled");
                        $("#modaldetalle .flexmodal .modal-forms a").remove();
                        $("#modaldetalle .flexmodal .modal-forms h3").html("Guardando");
                        $("#modaldetalle").stop().fadeIn();
                        $(".cotizacionDiv").find("#comentarios_renovacion").css('border-color', '#ebebeb');

                        $.ajax({
                            headers: {
                                'X-CSRF-TOKEN': token
                            },
                            url: $("#cotizacion").attr('data-action'),
                            type: 'POST',
                            data: ({
                                formname: formname,
                                emails: emails,
                                id_inmueble: id_inmueble,
                                servicios: data,
                                combos: combosArray,
                                total: totalCoti,
                                iva: iva,
                                subtotal: subtotal,
                                propietario: propietario,
                                propietarioInfo: propietariox,
                                comentarios_cotizacion: comentarios_cotizacion,
                                comentarios_preexistencias: comentarios_preexistencias,
                                comentarios_renovacion: comentarios_renovacion
                            }),
                            success: function(response) {
                                $("#modaldetalle .flexmodal .modal-forms h3").html(response);
                                $("#modaldetalle").stop().fadeIn();

                                setTimeout(() => {
                                    $(".modal").slideUp();
                                    $(".completado").slideUp();
                                    $(".complete").slideUp();
                                    $(".estadoxz").slideUp();
                                    $(".FormReasignar").slideDown();
                                    $(".modalEstado .pre").slideDown();
                                    $(".eliminarImage .pre").slideDown();
                                    $(".eliminarImagex .pre").slideDown();
                                }, 1000);
                            },
                            error: function(response) {
                                $("#modaldetalle .flexmodal .modal-forms h3").html(response);
                                setTimeout(() => {
                                    $(".modal").slideUp();
                                    $(".completado").slideUp();
                                    $(".complete").slideUp();
                                    $(".estadoxz").slideUp();
                                    $(".FormReasignar").slideDown();
                                    $(".modalEstado .pre").slideDown();
                                    $(".eliminarImage .pre").slideDown();
                                    $(".eliminarImagex .pre").slideDown();
                                }, 1000);
                            }
                        });
                    } else {
                        $(".cotizacionDiv").find(".error").html("Indique la razón de la renovación").slideToggle();
                        $(".cotizacionDiv").find("#comentarios_renovacion").css('border-color', 'red');
                        setTimeout(() => {
                            $(".cotizacionDiv").find(".error").slideToggle();
                        }, 1000);
                    }
                } else {
                    $(".cotizacionDiv").find(".error").html("La cotización tiene que ser mayor a 0").slideToggle();
                    setTimeout(() => {
                        $(".cotizacionDiv").find(".error").slideToggle();
                    }, 1000);
                }
            } else {
                $(".cotizacionDiv").find(".error").html("Seleccione un combo").slideToggle();
                setTimeout(() => {
                    $(".cotizacionDiv").find(".error").slideToggle();

                }, 1000);
            }

        });

        function guardarArray() {
            var data = []
            $("#cotizacion tbody tr").map(function() {
                var idservi = $(this).attr("id");
                var area = $(this).find('.variable').val();
                var valor = $(this).find(".valorF").attr("data-bruto");
                var notas = $(this).find(".notas").val();
                var estate = $(this).attr("data-estate");
                var id_update = $(this).attr("data-update");
                data.push({ id_servicio: idservi, preTotal: valor, area: area, notas: notas, id_update: id_update, estate: estate })
            })
            return data;
        }
        sumarCotizacion();
    }

    var thisForm = '';
    //***********************************************/
    function cargaF(thisForm) {
        $(thisForm + " a").addClass('disabled');
        $(thisForm + " span").addClass('hidden');
        $(thisForm + " a i").addClass('hidden');
        $(thisForm + " a").html('Cargando foto...');
        $("#modalCargando").stop().slideDown();
    }

    function terminaF(thisForm, data) {
        $(thisForm + " a").removeClass('disabled');
        $(thisForm + " a i").removeClass('hidden');
        $(thisForm + " a").html('Foto Cargada');
        $("#modalCargando .flexmodal .modal-forms h3").html(data);
        $("#modalCargando .flexmodal .modal-forms img").fadeOut();
        $("#modalCargando .flexmodal .modal-forms a").fadeIn();
    }
    $("#subir_video").on("click", function(e) {
        // var x = $(this).parents("form");
        // x.submit();
        e.preventDefault();
        thisForm = '#mvideos';
        var formname = $(thisForm);
        var formData = new FormData($(thisForm)[0]);
        $.ajax({
            url: formname.attr('action'),
            type: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function() {
                cargaF(thisForm);
            },
            success: function(data) {
                terminaF(thisForm, data);
            }
        });

    });
    $("#guardarEstiloVida").on("click", function(e) {
        e.preventDefault();
        var $form = $("#formEstiloVida");
        var data = $form.serialize();
        var method = $form.attr('method');
        $.ajax({
            url: $form.attr('action'),
            type: method,
            data: data,
            success: function(response) {
                $("#modaldetalle .flexmodal .modal-forms h3").html("Estilo de vida actualizado");
                $("#modaldetalle .flexmodal .modal-forms a").click(function(e) {

                    $("#modaldetalle").stop().fadeOut();
                    e.preventDefault();
                })
                $("#modaldetalle").stop().fadeIn();
            }
        });
    });

    $("#guardarInspeccion").on("click", function(e) {
        e.preventDefault();
        var $form = $("#inspeccion");
        var data = $form.serialize();
        var method = $form.attr('method');
        $.ajax({
            url: $form.attr('action'),
            type: method,
            data: data,
            success: function(response) {
                $("#modaldetalle .flexmodal .modal-forms h3").html(response);
                $("#modaldetalle .flexmodal .modal-forms a").click(function(e) {

                    $("#modaldetalle").stop().fadeOut();
                    e.preventDefault();
                })
                $("#modaldetalle").stop().fadeIn();
            }
        });
    });

    var id_media;
    var url;
    var token;
    var slidex;
    var contador;
    $(".multimedia-body-slider-item .delete").on("click", function() {
        slidex = $(this).parents(".multimedia-body-slider-item");
        id_media = $(this).attr("data-id");
        url = $(this).parent(".multimedia-body-slider-item").find("form").attr('action');
        token = $(this).parent(".multimedia-body-slider-item").find("form").find('input[name="_token"]').val();
        contador = $(".multimedia-body-slider input.deleteImage").length;

        if (contador == 1) {
            $("#aviso").text("Si elimina todas las fotos el cambio no se reflejara en el portal, recuerde mantener al menos una foto en cada publicación.");
        } else {
            $("#aviso").text();
        }

        $(".eliminarImage").slideDown();
    });

    $(".multimediav-body-slider-item .delete").on("click", function() {
        slidex = $(this).parents(".multimediav-body-slider-item");
        id_media = $(this).attr("data-id");
        url = $(this).parent(".multimediav-body-slider-item").find("form").attr('action');
        token = $(this).parent(".multimediav-body-slider-item").find("form").find('input[name="_token"]').val();
        contador = $(".multimedia-body-slider input.deleteImage").length;

        if (contador == 1) {
            $("#aviso").text("Si elimina todas las fotos el cambio no se reflejara en el portal, recuerde mantener al menos una foto en cada publicación.");
        } else {
            $("#aviso").text();
        }

        $(".eliminarImage").slideDown();
    });

    $(".eliminarImagen").on("click", function(e) {
        e.preventDefault();
        $(this).parents(".pre").slideUp();

        $('.eliminarImage .guardando').slideDown();

        var imagenes = [id_media];
        var idInmueble = $('#id_inmueble').val();
        if ($('.post').css('display') == 'block') {
            $('.post').css('display', 'none');
        }
        $.ajax({
            url: url,
            type: "PUT",
            data: {
                'formname': "eliminarGuias360",
                'id_imagen': imagenes,
                'id_inmueble': idInmueble
            },
            success: function(response) {
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: url,
                    type: 'PUT',
                    data: ({ id_media: id_media, estado_img: 0, formname: 'deleteImage' }),
                    success: function(response) {
                        //...
                        slidex.remove();
                        $('.eliminarImage .guardando').slideUp();
                        $('.eliminarImage .complete').slideDown();
                        showBtnEliminarMasivo();
                    }
                });
            }
        });
    })

    function progress(e) {

        if (e.lengthComputable) {
            var max = e.total;
            var current = e.loaded;

            var Percentage = (current * 100) / max;
            $("#modalCargando .progress-bar").css("width", Percentage + "%")

            if (Percentage >= 100) {
                $("#modalCargando .progress-bar").removeClass("progress-bar-primary");
                $("#modalCargando .progress-bar").addClass("progress-bar-green");

            }
        }
    }

    $("#fotos_planas").on("change", function() {
        uploadPhotos("#formulario_fotos_planas", "fotos_planas");
    });

    $("#fotos_360").on("change", function() {
        uploadPhotos("#formulario_fotos_360", "fotos_360");
    });

    function uploadPhotos(name, idInput) {
        var formname = $(name);
        var files = document.getElementById(idInput).files;
        var sizeTotal = 0;
        for (var i = 0; i < files.length; i++) {
            sizeTotal = sizeTotal + files[i].size;
        }
        if (sizeTotal / 1048576 < 200) {
            var formData = new FormData(formname[0]);
            $.ajax({
                url: formname.attr('action'),
                type: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                xhr: function() {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', progress, false);
                    }
                    return myXhr;
                },
                beforeSend: function() {
                    cargaF(thisForm);
                },
                success: function(data) {
                    terminaF(thisForm, data);
                }
            });
        } else {
            $(name)[0].reset();
            $(".file-input-name").hide();
            $("#modaldetalle .flexmodal .modal-forms h3").html("Se superó tamaño de lote de imágenes");
            $("#modaldetalle .flexmodal .modal-forms a").addClass("cerrarBtn");
            $("#modaldetalle").stop().slideDown();
            cerrarModales();
            return false;
        }
    }

    var cont = false;
    $(".auto_submit_item_x").on("change", function() {
        var $form = $(this).parents("form");
        var data = $form.serialize();
        var self = $(this);
        var name = $(this).attr("name");
        var html = "";
        var coments = $("#InfoValidation").val();
        $("#InfoValidation").css("border", "1px solid #ccc");
        $(".error").hide();
        cont = false;
        $.ajax({
            url: $form.attr('action'),
            type: 'PUT',
            data: data,
            success: function(response) {
                //...
                if (name == "inicial_localidad") {
                    $("#select2-chosen-6").html("Seleccione Barrio...");
                    $("select[name='inicial_barrio']").find("option").map(function() {
                        $(this).remove();
                    })
                    response.map(function(element) {
                        html += "<option value='" + element.id + "'>" + element.valor + "</option>"
                    })
                    $("select[name='inicial_barrio']").html(html);

                } else if (name == "inicial_ciudad") {
                    $("#select2-chosen-4").html("Seleccione Localidad...");
                    $("#select2-chosen-6").html("Seleccione Barrio...");
                    $("select[name='inicial_localidad']").find("option").map(function() {
                        $(this).remove();
                    })
                    $("select[name='inicial_barrio']").find("option").map(function() {
                        $(this).remove();
                    })
                    response.map(function(element) {
                        html += "<option value='" + element.id + "'>" + element.valor + "</option>"
                    })
                    $("select[name='inicial_localidad']").html(html);

                } else {}
            }
        });

    });

    function goToByScroll(id) {
        // Remove "link" from the ID
        id = id.replace("link", "");
        // Scroll
        $('html,body').animate({
                scrollTop: $("#" + id).offset().top - 100
            },
            'slow');
    }

    function cleanwarinings() {
        $("#admin").css("border", "1px solid #ebebeb");
        $("#pventa").css("border", "1px solid #ebebeb");
        $("#canon").css("border", "1px solid #ebebeb");
        $("#banosb").css("border", "1px solid #ebebeb");
        $("#s2id_tipotran").css("border", "1px solid #ebebeb");
        $("#InfoValidation").css("border", "1px solid #ebebeb");
        $("#habitacionesb").css("border", "1px solid #ebebeb");
        $("#parqueaderosb").css("border", "1px solid #ebebeb");
        $("#medidasParqueaderos").css("border", "1px solid #ebebeb");
        $("#type_parqueaderos").css("border", "1px solid #ebebeb");
        $("#acontronstruccionb").css("border", "1px solid #ebebeb");
        $("#areapb").css("border", "1px solid #ebebeb");
        $("#areapb").removeAttr("title");

        $(".errorVenta").remove();
        $(".errorCanon").remove();
    }

    function showTitleInvalidAreaPropertyLocal(minimumArea, localProperty = false) {
        if (localProperty) {
            $("#areapb").attr("title", 'El área construida debe ser mayor e igual a ' + minimumArea);
        }
    }

    function mapLocationx() {
        var lat = $("#map2").attr("data-lat");
        var lng = $("#map2").attr("data-lng");
        var title = $("#map2").attr("data-title");
        var myLatLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
        var directionsDisplay;
        var directionsService = new google.maps.DirectionsService();
        var mapx;

        function initialize() {
            directionsDisplay = new google.maps.DirectionsRenderer();
            var geocoder = new google.maps.Geocoder();
            var mapOptions = {
                scrollwheel: false,
                navigationControl: false,
                mapTypeControl: false,
                scaleControl: false,
                draggable: false,
                zoom: 18,
                center: myLatLng
            };
            mapx = new google.maps.Map(document.getElementById('map2'), mapOptions);
            var markerx = new google.maps.Marker({
                position: myLatLng,
                map: mapx,
                title: title,
                draggable: false
            });

            function updateDireccion(str) {
                $("#direccion").val(str);
            }

            function updateMarkerAddress(str) {
                $("#statusMap").fadeIn();
                document.getElementById('statusMap').innerHTML = str;
                setTimeout(function() {
                    $("#statusMap").fadeOut();
                }, 2000);

            }
            directionsDisplay.setMap(mapx);
            google.maps.event.addDomListener(document.getElementById('routebtn'), 'click', calcRoute);

        }

        function calcRoute() {
            $.post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDhFuhSJc6IoUArXeRPxb1TzsvrRTR2B7A", {},
                function(data) {
                    start = data.location;
                    end = myLatLng;
                    var stepin = new google.maps.LatLng(52.0688155, 19.9775557);
                    /*
            var startMarker = new google.maps.Marker({
                        position: start,
                        map: map,
                        draggable: true
                    });
                    var endMarker = new google.maps.Marker({
                        position: end,
                        map: map,
                        draggable: true
                    });
            */
                    var bounds = new google.maps.LatLngBounds();
                    bounds.extend(start);
                    bounds.extend(stepin);
                    bounds.extend(end);
                    mapx.fitBounds(bounds);
                    var request = {
                        origin: start,
                        destination: end,
                        travelMode: google.maps.TravelMode.DRIVING
                    };
                    directionsService.route(request, function(response, status) {
                        if (status == google.maps.DirectionsStatus.OK) {
                            directionsDisplay.setDirections(response);
                            directionsDisplay.setMap(mapx);
                        } else {
                            alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
                        }
                    });
                });

        }

        google.maps.event.addDomListener(window, 'load', initialize);
    }
    if ($("#map2").length) {
        mapLocationx();
    }

    function enviarPosicion(pos) {}

    function setIntervalLocation() {
        navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            enviarPosicion(pos);

        }, function() {});
        setInterval(function() {
            navigator.geolocation.getCurrentPosition(function(position) {
                var pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                enviarPosicion(pos);

            }, function() {});
        }, 900000)

    }

    function intervaLocation() {
        if (navigator.geolocation) {
            setIntervalLocation();
        } else {}
    }
    if (!$(".login-container").length > 0) {
        intervaLocation();
    }
    // FUNCIONES DIAN

    function startEventsDian() {
        $("#generarDireccionB").on("click", function() {
            crearDireccion();
        })
        $("#resetearComplemento").on("click", function() {
            complementos = "";
            crearDireccion();
        })
        $("#generarComplemento").on("click", function() {
            agregarComplemento();
        })
        $("#opendian").on("click", function(e) {
            e.preventDefault();
            $(".modaldian").slideToggle();
        })
        $("#guardarDireccion").on("click", function() {

            if (direccion != "" && direccion != undefined) {


                complementos = complementos.replace(/[#-]/g, "");
                // if ($("#cotizacion").length > 0 || $('.cotizacionDiv').length > 0) {
                updateDireccion(direccion);
                var token = $("#cotizacion").find('input[name="_token"]').val();
                var inmueble = $("#cotizacion").find('.coti_id_inmueble').val();
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: $("#inmuebleUpdate").attr('data-action'),
                    type: 'PUT',
                    data: ({ formname: 'actualizarDir', direccion: direccion, complementos: complementos }),
                    success: function(response) {
                        //...
                        updateMarkerAddress(response);
                        $("#direccion").val(direccion);
                        $("#search-btn").attr('data-direccion', direccion);
                        $("#complementossend").val(complementos);
                    }
                });

                // } else {
                //     updateDireccion(direccion);
                // }

                if (map != "") {
                    verificarCoordenadas();
                }





                $(".modaldian").slideToggle();
            } else {
                var message = "<p>Ingrese valores</p>"
                $(".gerardireccion .messages .alert-danger").show();
                $(".gerardireccion .messages .alert-danger").html(message);

                setTimeout(function() {
                    $(".gerardireccion .messages .alert-danger").hide();
                }, 1000)
            }

        });
    }

    function crearDireccion() {
        direccion = "";
        direccion += $("#dato_1_direccion").val();
        direccion += " " + $("#numero1").val();
        direccion += "" + $("#dato_2_direccion").val();
        if ($("#dato_bis2_direccion").val() != "") {
            direccion += " " + $("#dato_bis2_direccion").val() + " ";
        }
        if ($("#dato_bis_direccion").val() != "") {
            direccion += " " + $("#dato_bis_direccion").val() + " ";
        }
        direccion += "   #";
        direccion += $("#numero2").val();
        direccion += "" + $("#dato_letra4_direccion").val();
        if ($("#dato_ubicacion_direccion").val() != "") {
            direccion += " " + $("#dato_ubicacion_direccion").val();
        }
        direccion += " - ";
        direccion += $("#numero4").val();
        direccion += " " + $("#dato2_ubicacion_direccion").val();
        direccion = direccion.replace("  ", "");
        direcciony = direccion.replace(/[#-]/g, "");
        direcciony = direcciony.replace("  ", " ");
        $("#previewDir").val(direcciony);
        direccion_sin_complemtos = direccion;
        console.log("dirección: ", direccion);
        //direccion += ", Colombia, Bogota";

    }

    function agregarComplemento() {
        var direcciony;
        crearDireccion();
        var x = $("#complementos").val();
        var y = $("#complementos_text").val();
        if (x != "" && y != "" && direccion != undefined) {
            complementos += " " + $("#complementos").val() + " " + $("#complementos_text").val();
            complementos = complementos.replace("  ", "");
            direccion += " " + complementos;
            direcciony = direccion.replace(/[#-]/g, "");
            direcciony = direcciony.replace("  ", " ");
            complementos = complementos.replace(/[#-]/g, "");

            $("#complementos").select2('val', '');
            $("#complementos_text").val('');
            $("#previewDir").val(direcciony);
        } else {
            var message = "<p>Ingrese un complemento</p>"
            $(".gerardireccion .messages .alert-danger").show();
            $(".gerardireccion .messages .alert-danger").html(message);

            setTimeout(function() {
                $(".gerardireccion .messages .alert-danger").hide();
            }, 1000)
        }

    }

    function updateDireccion(str) {
        $("#direccion").val(direccion);
        $("#search-btn").attr('data-direccion', direccion);
        $("#complementossend").val(complementos);
    }

    function crearDireccion() {
        direccion = "";
        direccion += $("#dato_1_direccion").val();
        direccion += " " + $("#numero1").val();
        direccion += "" + $("#dato_2_direccion").val();
        if ($("#dato_bis2_direccion").val() != "") {
            direccion += " " + $("#dato_bis2_direccion").val() + "  ";
        }
        if ($("#dato_bis_direccion").val() != "") {
            direccion += " " + $("#dato_bis_direccion").val() + " ";
        }
        direccion += "  #";
        direccion += $("#numero2").val();
        direccion += "" + $("#dato_letra4_direccion").val();
        if ($("#dato_ubicacion_direccion").val() != "") {
            direccion += " " + $("#dato_ubicacion_direccion").val();
        }
        direccion += "-";
        direccion += $("#numero4").val();
        direccion += " " + $("#dato2_ubicacion_direccion").val();
        direccion = direccion.replace("  ", "");
        direcciony = direccion.replace(/[#-]/g, "");
        direcciony = direcciony.replace("  ", " ");
        $("#previewDir").val(direcciony);
        direccion_sin_complemtos = direccion;
        //direccion += ", Colombia, Bogota";
    }

    function agregarComplemento() {
        var direcciony;
        crearDireccion();
        var x = $("#complementos").val();
        var y = $("#complementos_text").val();
        if (x != "" && y != "" && direccion != undefined) {
            complementos += " " + $("#complementos").val() + " " + $("#complementos_text").val();
            complementos = complementos.replace("  ", "");
            direccion += " " + complementos;
            direcciony = direccion.replace(/[#-]/g, "");
            direcciony = direcciony.replace("  ", " ");
            complementos = complementos.replace(/[#-]/g, "");

            $("#complementos").select2('val', '');
            $("#complementos_text").val('');
            $("#previewDir").val(direcciony);
        } else {
            var message = "<p>Ingrese un complemento</p>"
            $(".gerardireccion .messages .alert-danger").show();
            $(".gerardireccion .messages .alert-danger").html(message);

            setTimeout(function() {
                $(".gerardireccion .messages .alert-danger").hide();
            }, 1000)
        }

    }

    function updateDireccion(str) {
        $("#direccion").val(direccion);
        $("#search-btn").attr('data-direccion', direccion);
        $("#complementossend").val(complementos);
    }

    // FUNCIONES PARA EL MAPA PUBLICADOR CREAR INMUEBLE

    function initializeMap() {
        var input = document.getElementById('direccion');
        var lat = $("#map").attr("data-lat");
        var lng = $("#map").attr("data-lng");
        var title = $("#map").attr("data-title");
        var myLatLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
        var mapOptions = {
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: true,
            zoom: 18,
            center: myLatLng,
            streetViewControl: false
        };
        var searchBox = new google.maps.places.SearchBox(input);
        map = new google.maps.Map(document.getElementById('map'), mapOptions);
        marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: title,
            draggable: true
        });
        google.maps.event.addListener(marker, 'dragend', function() {
            updateMarkerAddress("Actualizando Ubicacion...")
            updateLatLng(marker.getPosition());
            map.panTo(marker.getPosition());
            myLatLng = marker.getPosition();
        });
        map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
        });

        searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // For each place, get the icon, name and location.
            var bounds = new google.maps.LatLngBounds();
            places.forEach(function(place) {
                if (!place.geometry) {
                    return;
                }
                marker.setPosition(place.geometry.location);
                map.panTo(marker.getPosition());
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
            updateLatLng(marker.getPosition());
        });
        if ($('#direccion').val() != "" && idinmueble == '0') {
            direccion = $('#direccion').val();
            verificarCoordenadas();
        }
        directionsDisplay.setMap(map);
        google.maps.event.addDomListener(document.getElementById('routebtn'), 'click', calcRoute);
    }

    function getCoords() {
        // Creamos el objeto geodecoder
        var extr = document.getElementsByName("inicial_ciudad")[0];
        var ciudad = extr.options[extr.selectedIndex].text;
        var address = direccion_sin_complemtos + "," + ciudad + ", Colombia";
        if (address != '') {
            // Llamamos a la función geodecode pasandole la dirección que hemos introducido en la caja de texto.
            geocoder.geocode({ 'address': address }, function(results, status) {
                if (status == 'OK') {
                    // Mostramos las coordenadas obtenidas en el p con id coordenadas
                    //  document.getElementById("coordenadas").innerHTML='Coordenadas:   '+results[0].geometry.location.lat()+', '+results[0].geometry.location.lng();
                    // Posicionamos el marcador en las coordenadas obtenidas
                    var latitud = results[0].geometry.location.lat();
                    var longitud = results[0].geometry.location.lng();
                    marker.setPosition(results[0].geometry.location);
                    updateLatLng(results[0].geometry.location);
                    geocodePosition(results[0].geometry.location);
                    // Centramos el mapa en las coordenadas obtenidas
                    map.setZoom(19);
                    map.setCenter(marker.getPosition());
                    //agendaForm.showMapaEventForm();
                }
            });
        }
    }

    function verificarCoordenadas() {
        getCoords();
    }

    function updateLatLng(latLng) {
        var lat = latLng.lat();
        var lng = latLng.lng();

        $("#lat").val(lat);
        $("#lng").val(lng);

        var token = $("#cotizacion").find('input[name="_token"]').val();
        var inmueble = $("#cotizacion").find('.coti_id_inmueble').val();
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': token
            },
            url: $("#inmuebleUpdate").attr('data-action'),
            type: 'PUT',
            data: ({ formname: 'actualizarDir', inmueble: inmueble, lat: lat, lng: lng }),
            success: function(response) {
                //...
                updateMarkerAddress(response);
            }
        });


    }

    function geocodePosition(pos) {
        geocoder.geocode({
            latLng: pos
        }, function(responses) {
            complementos = complementos.replace(/[#-]/g, "");
            if ($("#cotizacion").length > 0) {
                if (responses && responses.length > 0) {
                    updateDireccion(responses[0].formatted_address);
                    var token = $("#cotizacion").find('input[name="_token"]').val();
                    var inmueble = $("#cotizacion").find('.coti_id_inmueble').val();
                    $.ajax({
                        headers: {
                            'X-CSRF-TOKEN': token
                        },
                        url: $("#inmuebleUpdate").attr('data-action'),
                        type: 'PUT',
                        data: ({ formname: 'actualizarDir', direccion: direccion, complementos: complementos }),
                        success: function(response) {
                            //...
                            updateMarkerAddress(response);
                            $("#direccion").val(direccion);
                            $("#complementossend").val(complementos);
                        }
                    });
                } else {
                    updateDireccion('Cannot determine address at this location.');
                }
            } else {
                updateDireccion(responses[0].formatted_address);
            }

        });
    }

    function updateMarkerAddress(str) {
        $("#statusMap").fadeIn();
        document.getElementById('statusMap').innerHTML = str;
        setTimeout(function() {
            $("#statusMap").fadeOut();
        }, 2000);

    }

    function calcRoute() {
        $.post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDhFuhSJc6IoUArXeRPxb1TzsvrRTR2B7A", {},
            function(data) {
                start = data.location;
                end = myLatLng;
                var stepin = new google.maps.LatLng(52.0688155, 19.9775557);
                /*
        var startMarker = new google.maps.Marker({
                    position: start,
                    map: map,
                    draggable: true
                });
                var endMarker = new google.maps.Marker({
                    position: end,
                    map: map,
                    draggable: true
                });
        */
                var bounds = new google.maps.LatLngBounds();
                bounds.extend(start);
                bounds.extend(stepin);
                bounds.extend(end);
                map.fitBounds(bounds);
                var request = {
                    origin: start,
                    destination: end,
                    travelMode: google.maps.TravelMode.DRIVING
                };
                directionsService.route(request, function(response, status) {
                    if (status == google.maps.DirectionsStatus.OK) {
                        directionsDisplay.setDirections(response);
                        directionsDisplay.setMap(map);
                    } else {
                        alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
                    }
                });
            }
        );

    }

    if ($("#map").length) {
        startEventsDian()
        loadClickMap();
    }

    function loadClickMap() {
        //funcion mostrar y ocultar mapa 
        $("#show-map-inmob").on("click", function() {
            $("#map").addClass("showMap");
            $("#ocultar-map").addClass("ocultar");
            initializeMap();
        });
    }

    var photospheres = [];

    function loadPredefinedPanorama() {
        setTimeout(() => {
            $(".item360").map(function(index) {
                this.setAttribute("is360", index);
            })
            loadpanoramas();
        }, 2000);
    }

    function loadpanoramas() {

        $(".item360.slick-active").map(function() {
            var path = $(this).find(".item-image360").attr("data-path");
            var index = $(this).attr("is360");

            $(this).find(".item-image360").html("");
            if (path != undefined) {
                var loader = document.createElement('div');
                loader.className = 'loader';
                loader.setAttribute("class", "yeah");
                var div = $(this).find(".item-image360")[0];
                photospheres[index] = new PhotoSphereViewer({
                    container: div,
                    panorama: path,
                    caption: 'Descripcion',
                    loading_img: '../../../img/photosphere-logo.gif',
                    longitude_range: [-7 * Math.PI / 8, 7 * Math.PI / 8],
                    latitude_range: [-3 * Math.PI / 4, 3 * Math.PI / 4],
                    anim_speed: '-2rpm',
                    default_fov: 50,
                    fisheye: true,
                    move_speed: 1.1,
                    time_anim: false,
                    gyroscope: true,
                    size: {
                        width: '100%',
                        height: '175px'
                    },
                    webgl: true,
                    navbar: [
                        'fullscreen', 'autorotate', 'zoom', 'download', 'markers',
                        'caption',
                        'gyroscope'

                    ]

                });

            } else {}

        });
    }
    loadPredefinedPanorama();

    $('.multimedia').on("click", '.slick-next', function() {
        loadpanoramas();
    })
    $('.multimedia').on("click", '.slick-prev', function() {
            loadpanoramas();
        })
        //Sliders
    if ($(".multimedia-body-slider").length > 0) {
        $('.multimedia-body-slider').slick({
            slidesToShow: 3,
            infinite: false,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            dots: false,
            draggable: false,
            swipe: false,
            responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        $('.multimediav-body-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            dots: false

        });
    }
    $('#dian').change(function() {
        let dian = this.value;
        let actual = $("#direccion").val();
        actual = `${actual} ${dian}`;
        $("#direccion").val(actual);
    });
    var $formEstado = "";



    /* Asignacion */
    /*Format number*/
    if ($(".tableclientes, .main-contentx").length) {
        $(".currencyx").map(function() {
            var number = $(this).html();
            var valorF = parseFloat(number);
            valorF = (valorF).formatMoney(2, '.', ',');
            $(this).html("$" + valorF);
        })
    }

    function cerrarModales() {
        $(".modal .close , .cerrarBtn").on("click", function(e) {
            e.preventDefault();
            $(".modal").slideUp();
            $(".completado").slideUp();
            $(".complete").slideUp();
            $(".estadoxz").slideUp();
            $(".FormReasignar").slideDown();
            $(".modalEstado .pre").slideDown();
            $(".eliminarImage .pre").slideDown();
            $(".eliminarImagex .pre").slideDown();
        })
    }
    cerrarModales();

    $(".checkbox input[type=checkbox]").on("change", function() {
        $(this).parents(".checkbox").toggleClass("checked");
    })
    var realActual;
    var realSlide;
    $(".ordenar .next").on("click", function(e) {
        e.preventDefault();
        var actual = $(this).attr("data-actual");
        var slide = $(this).attr("data-next");
        realActual = $(this).parents(".multimedia-body-slider-item").attr("data-real");
        realSlide = $(this).parents(".multimedia-body-slider-item").next().attr("data-real");
        actual = parseInt(actual);
        slide = parseInt(slide);
        ordenarFotos(actual - 1, slide - 1);
    })
    $(".ordenar .back").on("click", function(e) {
        e.preventDefault();
        var actual = $(this).attr("data-actual");
        var slide = $(this).attr("data-back");
        realActual = $(this).parents(".multimedia-body-slider-item").attr("data-real");
        realSlide = $(this).parents(".multimedia-body-slider-item").prev().attr("data-real");
        actual = parseInt(actual);
        slide = parseInt(slide);
        ordenarback(actual - 1, slide - 1);
    })

    function ordenarback(actual, slide) {
        var posicion;
        var actualSlide;
        var enter = true;
        $(".multimedia-body-slider-item").map(function() {
            var consolex = $(this).attr("data-slick-index");
            if (slide == consolex) {
                posicion = $(this);
            }
            if (actual == consolex) {
                actualSlide = $(this);
            }
            if (posicion != null && actualSlide != null && enter != false) {
                actualSlide.attr('data-slick-index', slide);
                actualSlide.find(".next").attr("data-actual", slide + 1);
                actualSlide.find(".next").attr("data-next", slide + 1 + 1);
                actualSlide.find(".back").attr("data-actual", slide + 1);
                actualSlide.find(".back").attr("data-back", slide);
                actualSlide.find(".contador").html(slide + 1);
                actualSlide.attr("data-real", realSlide);
                posicion.attr("data-real", realActual);
                posicion.attr('data-slick-index', actual);
                posicion.find(".next").attr("data-actual", actual + 1);
                posicion.find(".next").attr("data-next", actual + 1 + 1);
                posicion.find(".back").attr("data-actual", actual + 1);
                posicion.find(".back").attr("data-back", actual);
                posicion.find(".contador").html(actual + 1);
                actualSlide.insertBefore(posicion);
                enter = false;
                var token = posicion.find('input[name="_token"]').val();
                var valorPosx = realActual;

                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: posicion.find("form").attr('action'),
                    type: 'PUT',
                    data: ({
                        formname: 'media_orden',
                        id_media: posicion.find("form").find(".id_media").val(),
                        orden: valorPosx
                    }),
                    success: function(response) {}
                });
                var token = actualSlide.find('input[name="_token"]').val();
                var valorPosy = realSlide;

                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: actualSlide.find("form").attr('action'),
                    type: 'PUT',
                    data: ({
                        formname: 'media_orden',
                        id_media: actualSlide.find("form").find(".id_media").val(),
                        orden: valorPosy
                    }),
                    success: function(response) {}
                });
            }
        })
    }

    function ordenarFotos(actual, slide) {
        var posicion;
        var actualSlide;
        var enter = true;
        $(".multimedia-body-slider-item").map(function() {
            var consolex = $(this).attr("data-slick-index");
            if (slide == consolex) {
                posicion = $(this);
            }
            if (actual == consolex) {
                actualSlide = $(this);
            }
            if (posicion != null && actualSlide != null && enter != false) {
                actualSlide.attr('data-slick-index', slide);
                actualSlide.find(".next").attr("data-actual", slide + 1);
                actualSlide.find(".next").attr("data-next", slide + 1 + 1);
                actualSlide.find(".back").attr("data-actual", slide + 1);
                actualSlide.find(".back").attr("data-back", slide);
                actualSlide.find(".contador").html(slide + 1);
                actualSlide.attr("data-real", realSlide);
                posicion.attr("data-real", realActual);
                posicion.attr('data-slick-index', actual);
                posicion.find(".next").attr("data-actual", actual + 1);
                posicion.find(".next").attr("data-next", actual + 1 + 1);
                posicion.find(".back").attr("data-actual", actual + 1);
                posicion.find(".back").attr("data-back", actual);
                posicion.find(".contador").html(actual + 1);

                actualSlide.insertAfter(posicion);
                enter = false;
                var token = posicion.find('input[name="_token"]').val();
                var valorPosx = realActual;
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: posicion.find("form").attr('action'),
                    type: 'PUT',
                    data: ({
                        formname: 'media_orden',
                        id_media: posicion.find("form").find(".id_media").val(),
                        orden: valorPosx
                    }),
                    success: function(response) {}
                });
                var token = actualSlide.find('input[name="_token"]').val();
                var valorPosy = realSlide;
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: actualSlide.find("form").attr('action'),
                    type: 'PUT',
                    data: ({
                        formname: 'media_orden',
                        id_media: actualSlide.find("form").find(".id_media").val(),
                        orden: valorPosy
                    }),
                    success: function(response) {}
                });
            }
        })
    }

    $(".deleteImage").on("click", function() {
        showBtnEliminarMasivo();
    });

    function showBtnEliminarMasivo() {
        var n = $(".deleteImagex input:checked").length;
        if (n == 1 && !$('.eliminarImagenes').is(':visible')) {
            $(".eliminarImagenes").stop().slideToggle("slow");
            $("#box-select-all").stop().slideToggle("slow");
        } else if (n == 0 && $('.eliminarImagenes').is(':visible')) {
            $(".eliminarImagenes").css('display', 'none');
        }
    }


    $('#box-select-all').click(function(e) {
        var selectAllCheck = document.getElementsByClassName("deleteImagex");
        if ((this).classList.contains('checked')) {
            $(selectAllCheck).children().removeClass('checked');
        } else {
            $(selectAllCheck).children().addClass('checked');
        }
    });

    $(".eliminarImagex .eliminarImagenxd").on("click", function(e) {
        e.preventDefault();
        var url = $("#inmuebleUpdate").attr('data-action');
        $(this).parents(".pre").slideUp();

        $('.eliminarImagex .guardando').slideDown();

        var imagenes = [];
        $(".deleteImagex input:checked").map(function() {
            imagenes.push(this.value);
        })
        var token = $("#cotizacion").find('input[name="_token"]').val();

        var idInmueble = $('#id_inmueble').val();
        if ($('.post').css('display') == 'block') {
            $('.post').css('display', 'none');
        }
        $.ajax({
            url: url,
            type: "PUT",
            data: {
                'formname': "eliminarGuias360",
                'id_imagen': imagenes,
                'id_inmueble': idInmueble
            },
            success: function(response) {
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': token
                    },
                    url: url,
                    type: 'PUT',
                    data: ({ formname: 'eliminarImagenes', images: imagenes }),
                    success: function(response) {
                        //...
                        $(".multimedia-body-slider input:checked").map(function() {
                            $(this).parents(".multimedia-body-slider-item").remove();
                        })
                        $('.eliminarImagex .guardando').slideUp();
                        $('.eliminarImagex .complete').slideDown();
                        showBtnEliminarMasivo();
                    },
                    error: function(response) {
                        location.reload();
                    }
                });
            }
        });

    })

    $(".eliminarImagenes").on("click", function() {
        var fotosRestantes = $(".multimedia-body-slider input.deleteImage:not(:checked)").length;
        if (fotosRestantes == 0) {
            $("#avisox").text("Si elimina todas las fotos el cambio no se reflejara en el portal, recuerde mantener al menos una foto en cada publicación.");
        } else {
            $("#avisox").text("");
        }
        $(".eliminarImagex").slideDown();
    })

    $(".radio [type=radio]").on("change", function() {
        name = $(this).attr('name');
        $(".radio").find("input[type=radio][name='" + name + "']:not(:checked)").parents(".radio").removeClass("checked");
        $(this).parents(".radio").addClass("checked");
    })

    var oTable
    var checked_class = 'checked';
    // Initialize DataTable
    if ($(".table-1").length) {
        oTable = $('#table-1').dataTable({
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Spanish.json",
                searchPlaceholder: "Buscar"
            },
            "stateSave": true,
            "columnDefs": [{
                "targets": [0],
                "visible": false
            }],
            "bLengthChange": false,
        });
        // Initalize Select Dropdown after DataTables is created
    }
    $('.ordenarcliente').change(function() {
        switch (jQuery(this).val()) {
            case "_none_": // first option chosen, not associated with any column, do some default
                oTable.fnSort([
                    [0, 'asc']
                ]);
                break;
            case "fecha_asc":
                oTable.fnSort([
                    [0, 'asc']
                ]);
                break;
            case "fecha_desc":
                oTable.fnSort([
                    [0, 'desc']
                ]);
                break;
        }
    });
    var action = "";
    var theCoso = "";
    var theCo = "";
    var normal = "";

    $(".coments .coment").on("click", function(e) {
        e.preventDefault();
        var comentarios = $(this).parents(".coments").find(".comments-hidden .comments").clone();
        var id = $(this).attr('data-id-inmueble');
        action = $(this).parents("tr").find(".modalcomments-form").attr("action");
        normal = $(this).parents(".coments").find(".comments-hidden .comments");
        theCoso = comentarios;
        $("#modal-1").find('.inmueble_id').val(id);
        $("#modal-1").find("form").attr("action", action);
        $("#modal-1 .modal-body").html(comentarios[0].innerHTML);
        $("#modal-1").stop().fadeIn();
    })
    if ($('.espaciosContainer').length > 0) {
        function ordenar() {
            let cantidadBanos = $('input[type=radio][name=infobas_banos]:checked').attr('data-real');
            let cantidadHabitaciones = $('input[type=radio][name=infobas_habitaciones]:checked').attr('data-real');
            let interioresComedor = $('input[type=radio][name=interiores_tcomedor]:checked').val();
            let exteriores_nterrazas = $('select[name=exteriores_nterrazas]').val();
            let deposito = $('input[type=checkbox][name=exteriores_deposito]:checked').val();
            let cuarto_servicio = $('input[type=checkbox][name=interiores_cuarto_servicio]:checked').val();
            let interiores_bano_servicio = $('input[type=checkbox][name=interiores_bano_servicio]:checked').val();

            var c = 1;
            var h = 1;

            let newOrder = $(".espaciosform").sort(function(a, b) {
                return parseFloat(a.attributes["data-orden"].value) - parseFloat(b.attributes["data-orden"].value)
            })
            newOrder.map(function() {
                //Baños
                if (cantidadBanos != '0') {
                    if (this.attributes["data-orden"].value == '7' || this.attributes["data-orden"].value == '9' || this.attributes["data-orden"].value == '11' || this.attributes["data-orden"].value == '12' || this.attributes["data-orden"].value == '13') {
                        if (c <= parseInt(cantidadBanos)) {
                            this.classList.add('bano');
                            this.classList.remove('banoDisabled');

                            c++;
                        } else {
                            this.classList.add('banoDisabled');
                        }
                    }
                } else {
                    if (this.attributes["data-orden"].value == '7' || this.attributes["data-orden"].value == '9' || this.attributes["data-orden"].value == '11' || this.attributes["data-orden"].value == '12' || this.attributes["data-orden"].value == '13') {
                        this.classList.add('banoDisabled');
                    }
                }
                //Habitaciones 
                if (cantidadHabitaciones != '0') {
                    if (this.attributes["data-orden"].value == '14' || this.attributes["data-orden"].value == '16' || this.attributes["data-orden"].value == '17' || this.attributes["data-orden"].value == '18') {
                        if (h <= parseInt(cantidadHabitaciones)) {
                            this.classList.add('habitacion');
                            this.classList.remove('banoDisabled');

                            h++;
                        } else {
                            this.classList.add('banoDisabled');
                        }
                    }
                } else {
                    if (this.attributes["data-orden"].value == '14' || this.attributes["data-orden"].value == '16' || this.attributes["data-orden"].value == '17' || this.attributes["data-orden"].value == '18') {
                        this.classList.add('banoDisabled');
                    }
                }
                //Sala y comedor
                if (interioresComedor != '0') {
                    if (this.attributes["data-id"].value == '77' || this.attributes["data-id"].value == '66') {
                        this.classList.add('interiores');
                        this.classList.remove('banoDisabled');

                    } else if (this.attributes["data-id"].value == '78') {
                        this.classList.add('interiores');
                        this.classList.add('banoDisabled');
                    }
                } else {
                    if (this.attributes["data-id"].value == '78') {
                        this.classList.add('interiores');
                        this.classList.remove('banoDisabled');

                    } else if (this.attributes["data-id"].value == '77' || this.attributes["data-id"].value == '66') {
                        this.classList.add('interiores');
                        this.classList.add('banoDisabled');
                    }
                }
                //Terrazas

                if (exteriores_nterrazas != '0' && exteriores_nterrazas != '') {
                    if (this.attributes["data-id"].value == '80') {
                        this.classList.add('terraza');
                        this.classList.remove('banoDisabled');
                    }
                } else {
                    if (this.attributes["data-id"].value == '80') {
                        this.classList.add('terraza');
                        this.classList.add('banoDisabled');
                    }
                }
                //Deposito
                if (deposito == '1') {
                    if (this.attributes["data-id"].value == '68') {
                        this.classList.add('deposito');
                        this.classList.remove('banoDisabled');
                    }
                } else {
                    if (this.attributes["data-id"].value == '68') {
                        this.classList.add('deposito');
                        this.classList.add('banoDisabled');
                    }
                }
                //cuarto_servicio
                if (cuarto_servicio == '1') {
                    if (this.attributes["data-id"].value == '67') {
                        this.classList.add('c_servicio');
                        this.classList.remove('banoDisabled');
                    }
                } else {
                    if (this.attributes["data-id"].value == '67') {
                        this.classList.add('c_servicio');
                        this.classList.add('banoDisabled');
                    }
                }
                //interiores_bano_servicio
                if (interiores_bano_servicio == '1') {
                    if (this.attributes["data-id"].value == '64') {
                        this.classList.add('ib_servicio');
                        this.classList.remove('banoDisabled');
                    }
                } else {
                    if (this.attributes["data-id"].value == '64') {
                        this.classList.add('ib_servicio');
                        this.classList.add('banoDisabled');
                    }
                }



            })
            newOrder.sort(function(a, b) {
                return parseFloat(a.attributes["data-orden"].value) - parseFloat(b.attributes["data-orden"].value)
            })
            $('.espaciosContainer').html(newOrder);

        }
        ordenar();
        $('input[type=radio][name=infobas_banos]').change(function() {
            ordenar();
        });
        $('input[type=radio][name=infobas_habitaciones]').change(function() {
            ordenar();
        });
        $('input[type=radio][name=interiores_tcomedor]').change(function() {
            ordenar();
        });
        $('select[name=exteriores_nterrazas]').change(function() {
            ordenar();
        });
        $('input[type=checkbox][name=exteriores_deposito]').change(function() {
            ordenar();
        });
        $('input[type=checkbox][name=interiores_cuarto_servicio]').change(function() {
            ordenar();
        });
        $('input[type=checkbox][name=interiores_bano_servicio]').change(function() {
            ordenar();
        });

    }

    function loaderClientes() {
        $('.colx__load--loader').hide();
    }

    


    window.onload = function() {
        xAutosubmit();
        loaderClientes();
    };
});
// if ($('.tableclientes').length > 0) {
//     var item = sessionStorage.getItem('query');
//     if (item == null) {
//         sessionStorage.setItem('query', '');
//         item = sessionStorage.getItem('query');
//     }
//     var query = document.getElementById("searchbox").value = item;

//     document.getElementById("searchbox").addEventListener('keyup', function (e) {
//         sessionStorage.setItem('query', this.value);
//     })
// }
//Tabla usuarios
var oTablex
    // Initialize DataTable
if ($(".tableoportunidades").length) {
    oTablex = $('#table-3').dataTable({
        "stateSave": true,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Spanish.json",
            searchPlaceholder: "Buscar"
        },
        "aoColumnDefs": [{
            "bSortable": false,
            "paging": false,
            "orderMulti": false,
            "searchable": false,
            "ordering": false,
            "aTargets": [3]
        }],
        "bLengthChange": false
    });
    // Initalize Select Dropdown after DataTables is created
}

if ($("#updateUsuario").length) {
    $("#inmos").select2({
        ajax: {
            url: "../searchInmo",
            dataType: 'json',
            delay: 250,
            data: function(params) {
                return {
                    q: params.term, // search term
                    page: params.page
                };
            },
            processResults: function(data, page) {
                // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data
                return data.items;
            },
            cache: true
        },
        minimumInputLength: 1,
        templateResult: formatRepo, // omitted for brevity, see the source of this page
        templateSelection: formatRepoSelection // omitted for brevity, see the source of this page
    });
}

function formatRepo(repo) {
    if (repo.loading) return repo.text;

    var markup = '<div class="clearfix">' +
        '<div class="col-sm-1">' +
        '<img src="' + repo.owner.avatar_url + '" style="max-width: 100%" />' +
        '</div>' +
        '<div clas="col-sm-10">' +
        '<div class="clearfix">' +
        '<div class="col-sm-6">' + repo.full_name + '</div>' +
        '<div class="col-sm-3"><i class="fa fa-code-fork"></i> ' + repo.forks_count + '</div>' +
        '<div class="col-sm-2"><i class="fa fa-star"></i> ' + repo.stargazers_count + '</div>' +
        '</div>';

    if (repo.description) {
        markup += '<div>' + repo.description + '</div>';
    }

    markup += '</div></div>';

    return markup;
}

function formatRepoSelection(repo) {
    return repo.full_name || repo.text;
}
$(document).ready(function() {
    if ($(".select2").length > 0) {
        $(".select2").select2({
            theme: "bootstrap"
        })
    }

})


if ($("#updateUsuario").length) {
    $(document).ready(function() {
        $(".zonas").select2({
            theme: "bootstrap",
            multiple: true,
            placeholder: "Seleccione zona",
        })
        $(".select_buscable").select2({
            theme: "bootstrap",
            multiple: false,
            placeholder: "Seleccione zona",
        })
        $(".zonas").select2({
            theme: "bootstrap",
            multiple: true,
            placeholder: "Seleccione zona",
        })
        $(".inmos").select2({
            theme: "bootstrap",
            placeholder: "Seleccione inmobiliaria",
            multiple: true,
            ajax: {
                url: "../../searchInmo",
                dataType: 'json',
                delay: 250,
                data: function(params) {
                    return {
                        q: params.term // search term
                    };
                },
                processResults: function(data, params) {
                    // parse the results into the format expected by Select2.
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data
                    var payload = {
                        results: data
                    };
                    return payload;
                },
                cache: true
            },
            minimumInputLength: 4,
            templateResult: function(result) {
                if (result.text != "Searching…") {
                    return result["id"] + "-" + result["nombre"];
                } else {
                    return result.text;
                }
            }, // omitted for brevity, see the source of this page
            templateSelection: function(selection) {
                    if (selection["nombre"] != "" && selection["nombre"] != null) {
                        return selection["id"] + "-" + selection["nombre"];
                    } else {
                        return selection["text"];
                    }
                } // omitted for brevity, see the source of this page
        });
    })
}
if ($("#crearUsuario").length) {
    $(document).ready(function() {
        $(".zonas").select2({
            theme: "bootstrap",
            multiple: true,
            // allowClear: true,
            // placeholder: "Seleccione zona",
        })
        $(".inmos").select2({
            theme: "bootstrap",
            // placeholder: "Seleccione inmobiliaria",
            multiple: true,
            // allowClear: true,
            // ajax: {
            //     url: "../searchInmo",
            //     dataType: 'json',
            //     delay: 250,
            //     data: function (params) {
            //         return {
            //             q: params.term // search term
            //         };
            //     },
            //     processResults: function (data, params) {
            //         // parse the results into the format expected by Select2.
            //         // since we are using custom formatting functions we do not need to
            //         // alter the remote JSON data
            //         var payload = {
            //             results: data
            //         };
            //         return payload;
            //     },
            //     cache: true
            // },
            // minimumInputLength: 4,
            // templateResult: function (result) {
            //     if (result.text != "Searching…") {
            //         return result["id"] + "-" + result["nombre"];
            //     } else {
            //         return result.text;
            //     }
            // }, // omitted for brevity, see the source of this page
            // templateSelection: function (selection) {
            //     return selection["id"] + "-" + selection["nombre"];
            // } // omitted for brevity, see the source of this page
        });
    })

    window.onload = function() {
        document.getElementById("password1").onchange = validatePassword;
        document.getElementById("password2").onchange = validatePassword;
    }

    function validatePassword() {
        var pass2 = document.getElementById("password2").value;
        var pass1 = document.getElementById("password1").value;
        if (pass1 != pass2)
            document.getElementById("password2").setCustomValidity("Las contraseñas no coinciden");
        else
            document.getElementById("password2").setCustomValidity('');
        //empty string means no validation error
    }

}
$(".actualizaCliente").on("click", function(e) {
    e.preventDefault();
    var $form = $("#updateCliente");
    var data = $form.serialize();
    var method = $form.attr('method');
    $(".guardarClientex").slideDown();
    $(".guardarClientex .messagereasign .guardando").slideDown();
    $.ajax({
        url: $form.attr('action'),
        type: method,
        data: data,
        success: function(response) {
            //...
            $(".guardarClientex .messagereasign .guardando").slideUp();
            $(".guardarClientex .messagereasign .complete").slideDown();
        }
    });
})
$(".actualizaUsuario").on("click", function(e) {
    e.preventDefault();
    var $form = $("#updateUsuario");
    var data = $form.serialize();
    var method = $form.attr('method');
    $(".guardarClientex").slideDown();
    $(".guardarClientex .messagereasign .guardando").slideDown();
    $.ajax({
        url: $form.attr('action'),
        type: method,
        data: data,
        success: function(response) {
            //...
            $(".guardarClientex .messagereasign .guardando").slideUp();
            $(".guardarClientex .messagereasign .complete").slideDown();
        }
    });
})
$(".editarAsesor").on("click", function(e) {
    e.preventDefault();
    $(this).parents("td").find(".asesorInmo").stop().slideToggle();
})
$(".actualizarParametro").on("click", function(e) {
    e.preventDefault();
    var $form = $(this).parents("form");
    var data = $form.serialize();
    var method = $form.attr('method');
    $.ajax({
        url: $form.attr('action'),
        type: method,
        data: data,
        success: function(response) {
            $form.find(".alert-success").removeClass("hidden");
            $form.find(".alert-success").show();
            setTimeout(function() { $form.find(".alert-success").fadeOut(); }, 3000);
            location.reload();

        }
    });
})
if ($(".imagenesOrder").length > 0) {
    var el = document.getElementById('imagenesOrder');
    var sortable = Sortable.create(el);
}

function sumarCotizacionx(id) {
    var total = 0;
    $("#" + id + " .sumar").map(function(e) {
        var valor = this.value;
        valor = valor.replace(/,/g, "");
        valor = valor.replace("$", "");
        valor = parseFloat(valor);
        total += valor;
    })
    return total = (total).formatMoney(2, '.', ',');
}
$(".formulario .sumar").on("keyup", function() {
    if ($(this).hasClass("proveedor")) {
        var proveedor = this.value;
        proveedor = proveedor.replace(/,/g, "");
        proveedor = proveedor.replace("$", "");
        proveedor = parseFloat(proveedor);
        c_ciencuadras = proveedor * 0.07;
        m_asistencia = proveedor * 0.05;
        c_ciencuadras = (c_ciencuadras).formatMoney(2, '.', ',');
        m_asistencia = (m_asistencia).formatMoney(2, '.', ',');
        $(this).parents(".formulario").find(".c_ciencuadras").val("$" + c_ciencuadras);
        $(this).parents(".formulario").find(".m_asistenciar").val("$" + m_asistencia);
        id = $(this).parents(".formulario").attr("id");
        var total = sumarCotizacionx(id);
        $(this).parents(".formulario").find(".tarifa").val("$" + total);
    } else {
        id = $(this).parents(".formulario").attr("id");
        var total = sumarCotizacionx(id);
        $(this).parents(".formulario").find(".tarifa").val("$" + total);
    }
})
if ($(".enviarInmueble").length > 0) {

    $("select[name=inicial_departamento]").change(function() {
        let departamento = $("select[name=inicial_departamento]").val();
        let token = $("input[name=_token]").val();
        if (departamento != '0' && departamento != '') {
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': token
                },
                url: $("#enviarInmueble").attr('action'),
                type: $("#enviarInmueble").attr('method'), //'POST',
                data: ({ formname: 'consultar_ciudad', id_departamento: departamento }),
                success: function(response) {
                    let html = "";
                    html += "<option selected value=''>Seleccione ciudad ...</option>";
                    response.map(function(element) {
                        html += "<option value='" + element.id + "'>" + element.valor + "</option>"
                    })
                    $("select[name='inicial_ciudad']").next(".select2").find(".select2-selection__rendered").html("Seleccione ciudad...");
                    $("select[name='inicial_localidad']").next(".select2").find(".select2-selection__rendered").html("Seleccione localidad...");
                    $("select[name='inicial_barrio']").next(".select2").find(".select2-selection__rendered").html("Seleccione barrio...");

                    $("select[name='inicial_ciudad']").html(html);
                }
            });
        }
    });

    $("select[name=inicial_ciudad]").change(function() {
        let ciudad = $("select[name=inicial_ciudad]").val();
        let token = $("input[name=_token]").val();
        //MOSTRAR UN LOADING PARA LA CARGA TAN PESADA
        $("select[name='inicial_barrio']").next(".select2").find(".select2-selection__rendered").html("Cargando...");
        //MOSTRAR UN LOADING PARA LA CARGA TAN PESADA
        if (ciudad != '0' && ciudad != '') {
            $.ajax({
                headers: { 'X-CSRF-TOKEN': token },
                url: $("#enviarInmueble").attr('action'),
                type: $("#enviarInmueble").attr('method'), //'POST',
                data: ({ formname: 'consultar_barrio', id_ciudad: ciudad }),
                //data: ({ formname: 'consultar_localidad', id_ciudad: ciudad }),
                success: function(response) {
                    // let html = "";
                    // html += "<option selected value=''>Seleccione localidad ...</option>";
                    // response.map(function (element) {
                    //      html += "<option value='" + element.id + "'>" + element.valor + "</option>"
                    // })
                    // $("select[name='inicial_localidad']").next(".select2").find(".select2-selection__rendered").html("Seleccione localidad...");
                    // $("select[name='inicial_barrio']").next(".select2").find(".select2-selection__rendered").html("Seleccione barrio...");
                    // $("select[name='inicial_localidad']").html(html); 
                    let html = "";
                    html += "<option selected value=''>Seleccione barrio ...</option>";
                    response.map(function(element) {
                        html += "<option value='" + element.id + "'>" + element.valor + "," + element.localidad + "</option>"
                    })
                    $("select[name='inicial_barrio']").next(".select2").find(".select2-selection__rendered").html("Seleccione barrio...");
                    $("select[name='inicial_barrio']").html(html);
                }
            });
        }
    });

    $("select[name=inicial_localidad]").change(function() {
        let localidad = $("select[name=inicial_localidad]").val();
        let token = $("input[name=_token]").val();
        if (localidad != '0' && localidad != '') {
            $.ajax({
                headers: { 'X-CSRF-TOKEN': token },
                url: $("#enviarInmueble").attr('action'),
                type: $("#enviarInmueble").attr('method'), //'POST',
                data: ({ formname: 'consultar_barrio', id_localidad: localidad }),
                success: function(response) {
                    let html = "";
                    html += "<option selected value=''>Seleccione barrio ...</option>";
                    response.map(function(element) {
                        html += "<option value='" + element.id + "'>" + element.valor + "</option>"
                    })
                    $("select[name='inicial_barrio']").next(".select2").find(".select2-selection__rendered").html("Seleccione barrio...");
                    $("select[name='inicial_barrio']").html(html);
                }
            });
        }
    });
}

$("#inmobiliariasAlist").on("change", function() {
    var $form = $(this).parents("form");
    var method = $form.attr('method');
    var token = $form.find('input[name="_token"]').val();
    var inmo = $form.find('select[name="inmobiliarias"]').val();

    $.ajax({
        headers: {
            'X-CSRF-TOKEN': token
        },
        url: $form.attr('action'),
        type: method,
        data: ({ formname: "searchporcentaje", inmob: inmo }),
        success: function(response) {
            //...
            if (response != null || response != "" || response != undefined) {
                $form.find('input[name="mailsinmo"]').tagsinput('removeAll');
                $form.find('input[name="pcien"]').val(response.porcien);
                $form.find('input[name="pinmo"]').val(response.porinmo);
                $form.find('input[name="mailsinmo"]').tagsinput('add', response.contacto);
            }
        }
    });
})
$(".enviarInmueble").on("click", function(e) {
    e.preventDefault();

    var ciudad = $("#ciudadx").val();
    var localidad = $("select[name=inicial_localidad").val();
    var barrio = $("select[name=inicial_barrio").val();
    var direccion = $("#direccion").val();
    var tipox = $("#tipox").val();
    var inicial_estrato = $("select[name=inicial_estrato").val();
    var inmox = $("select[name=inicial_inmo").val();
    var inicial_transaccion = $("select[name=inicial_transaccion").val();
    var lat = $("#lat").val();
    if (ciudad == 0) {
        $("#statusForm").html("Campo Ciudad es requerido");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);

    } else if (localidad == "0" || localidad == "") {
        $("#statusForm").html("Campo localidad es requerido");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else if (barrio == "0" || barrio == "") {
        $("#statusForm").html("Campo barrio es requerido");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else if (tipox == "") {
        $("#statusForm").html("Campo tipo inmueble es requerido");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else if (inicial_transaccion == "") {
        $("#statusForm").html("Seleccione transacción");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else if (direccion == "") {
        $("#statusForm").html("Campo dirección es requerido");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else if (lat == "") {
        $("#statusForm").html("Seleccione un punto en el mapa");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else if ((inicial_estrato == "" || inicial_estrato == "7") && tipox != 38) {
        $("#statusForm").html("Seleccione estrato");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else if (inmox == "") {
        $("#statusForm").html("Seleccione inmobiliaria");
        $("#statusForm").slideDown();
        setTimeout(function() { $("#statusForm").slideUp(); }, 3000);
    } else {
        var $form = $(this).parents("form");
        var data = $form.serialize();
        var method = $form.attr('method');
        $(".guardarInm").slideDown();
        $(".guardarInm .guardando").slideDown();
        $.ajax({
            url: $form.attr('action'),
            type: method,
            data: data,
            success: function(response) {
                //...
                var url = $(".continuarInm").attr("href");
                $(".guardarInm .guardando").slideUp();
                $(".guardarInm .complete").slideDown();
                $(".guardarInm .complete .continuarInm").attr("href", url + "/" + response + "/edit");
            }
        });
    }
})

//Dian



$('.Ipropietario').on("click", function() {
    if (this.checked) {
        $('.infoPropietario').stop().slideToggle();
    } else {
        $('.infoPropietario').stop().slideToggle();
    }
})



if ($('.tableInmueblesXtreme').length > 0) {
    var oTableInmuebles;

    oTableInmuebles = $('#tableInmuebles').DataTable({
        "stateSave": true,
        "pageLength": 15,
        "bPaginate": false,
        "bInfo": false,
        "sInfo": false,
        searching: false,
        paging: false,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Spanish.json",
            searchPlaceholder: "Buscar"
        },
        drawCallback: function() {
            $('.select2').select2({
                theme: "bootstrap"
            });
        },
        "bLengthChange": false

    });


    $(document).ready(function() {
        if (sessionStorage.getItem(itemDinamico('buscarTablaInput'))) {
            // Restaura el contenido al campo de texto
            var buscarTablaInput = sessionStorage.getItem(itemDinamico('buscarTablaInput'));
            $("#buscarTablaInput").val(buscarTablaInput);
        }
        if (sessionStorage.getItem(itemDinamico('ultimaPagina'))) {
            // Restaura el contenido al campo de texto
            var tempPagina = sessionStorage.getItem(itemDinamico('ultimaPagina'));
            ajaxInmuebles(tempPagina);
        } else {
            ajaxInmuebles(1);
        }



        $("#buscarTablaInputBtn").click(function() {
            $(".tableInmueblesXtreme").hide();
            $(".cargando").show();
            ajaxInmuebles(1);
        })
    })

    function itemDinamico(item) {
        if (window.location.pathname == "/admin/clientes") {
            return item + "1";
        }
        if (window.location.pathname == "/admin/finalizadas") {
            return item + "2";
        }
        if (window.location.pathname == "/admin/inmueblestados") {
            return item + "3";
        }
    }

    function ajaxInmuebles(pagina) {
        sessionStorage.setItem(itemDinamico('ultimaPagina'), pagina);
        sessionStorage.setItem(itemDinamico('buscarTablaInput'), $("#buscarTablaInput").val());

        var id = $("#tipodeinmueble").attr("data-tipo");
        var xmlhttp = new XMLHttpRequest();
        var url = "getinmuebles/" + id + "?page=" + pagina + "&buscar=" + $("#buscarTablaInput").val();

        xmlhttp.onreadystatechange = function() {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    var inmueblesAr = JSON.parse(this.responseText);
                    montarInmuebles(inmueblesAr);
                } else {}
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }

    function initInmuebles() {
        // Restore state
        var state = oTableInmuebles.state.loaded();
        if (state) {
            oTableInmuebles.page(parseInt((state.start + 1) / 15)).draw('page');
        }
        window.otablex = oTableInmuebles;

        var action = "";
        var theCoso = "";
        var theCo = "";
        var normal = "";

        $(".editarAsesor").on("click", function(e) {
            e.preventDefault();
            $(this).parents("td").find(".asesorInmo").stop().slideToggle();
        })

        var $trdestroy = "";
        $(".reasignar").on("click", function(e) {
            e.preventDefault();
            $trdestroy = $(this).parents("tr");
            var inmueble = $(this).attr("data-idinmueble");
            $(".modalreasignar").slideDown();
            $(".modalreasignar #inmueble_reasignado").val(inmueble);
        })

        /* Asignacion */
        var $formEstadox = "";

        var $formEstado = "";
        $(".enviarAsignacion").on("click", function(e) {
            e.preventDefault();
            $formEstadox = $(this).parents("form");
            $(".FormReasignar").slideUp();
            $(".estadoxz").slideDown();
        })

        $(".enviarAsignacionpre").on("click", function(e) {
            e.preventDefault();
            var data = $formEstadox.serialize();
            var method = 'POST';
            $(this).parents(".pre").slideUp();
            $('.modalreasignar .guardando').slideDown();
            $.ajax({
                url: $formEstadox.attr('action'),
                type: method,
                data: data,
                success: function(response) {
                    $('.modalreasignar .guardando').slideUp();
                    $('.modalreasignar .complete').slideDown();
                },
                error: function(error) {}
            });
        })
        $(".estado_inmueble").on("change", function() {
            $formEstado = $(this).parents("form");
            $(".modalEstado").slideDown();
        })

        $('#cerrar-modal').click(function() {
            $('#modal-estados').hide();
            var inputsAll = $(".estados-inm").find('.estado_inmueble');
            $.each(inputsAll, function() {
                $(this).val("0");
            });
        });

        $(".enviarEstado").on("click", function(e) {
            e.preventDefault();
            var data = $formEstado.serialize();
            var method = 'POST';
            $(this).parents(".pre").slideUp();
            $('.modalEstado .guardando').slideDown();

            let token = $("input[name=_token]").val();
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': token
                },
                url: $formEstado.attr('action'),
                type: method,
                data: data,
                success: function(response) {
                    $('.modalEstado .guardando').slideUp();
                    $('.modalEstado .complete').slideDown();
                },
                error: function(error) {}
            });
        })

    }

    function crearPaginador(total, pagina) {
        var conatiner_pag = $('#paginacion_div')

        conatiner_pag.pagination({
            dataSource: total,
            pageSize: 15,
            pageNumber: pagina,
            showPrevious: false,
            autoHidePrevious: true,
            showNext: false,
            autoHideNext: true,
            callback: function(data, pagination) {},
            afterPageOnClick: function(data) {

                var pag = conatiner_pag.pagination('getSelectedPageNum');

                $(".tableInmueblesXtreme").hide();
                $(".cargando").show();
                ajaxInmuebles(pag);
            }
        })
    }

    function montarInmuebles(inmueblesAr) {

        oTableInmuebles.clear().draw();
        var total = [];
        for (var index = 1; index <= inmueblesAr.inmuebles_total; index++) {
            total.push(index);
        }
        crearPaginador(total, inmueblesAr.pagActual);

        var assets = inmueblesAr.assets
        var estadosVisual = inmueblesAr.estadosVisual
        var estados = inmueblesAr.estados
        var inmueblesArray = inmueblesAr.inmuebles


        if (inmueblesArray.length != 0) {
            var cont = 0;
            var global = 0;

            let html = '';
            oTableInmuebles.clear().draw();
            inmueblesArray.map(function(inmuebles, index) {

                //     for (var i = 0; i < 18; i++) {
                var fila = templateInmuebles(inmuebles, estados, assets, estadosVisual);
                //         delete inmuebles[i];
                //     }
                oTableInmuebles.rows.add($(fila)).draw();
                //     html = '';
                // setTimeout(function () {
                //     inmuebles.map(function (inmueble) {
                //         html += templateInmuebles(inmueble, estados, assets, estadosVisual);
                //         if (cont == 18) {
                //             oTableInmuebles.rows.add($(html)).draw();
                //             html = '';
                //             cont = 0;
                //             $(".cargando").hide();
                //             $(".tableInmueblesXtreme").show();
                //         }
                //         cont++;
                //     })

                //     oTableInmuebles.rows.add($(html)).draw();
                //     cont = 0;
                //     html = '';
                //     $(".cargando").hide();
                //     $(".tableInmueblesXtreme").show();
                //     
                // }, 1000);


            });

            $(".tableInmueblesXtreme").show();
            $(".cargando").hide();
            initInmuebles();
            initComents();

        } else {
            $(".cargando").hide();
            $(".tableInmueblesXtreme").show();
        }

    }

    //************Admin*****************/


    //usuarios

    function templateInmuebles(inmueble, estados, assets, estadosVisual) {

        var c = 0;
        var n = 0;
        var optionStados = "";
        var numCommentarios = "";

        estados.forEach(function(estado, index) {
            if (inmueble.estado_inmueble == index) {

                optionStados += `<option value="${index}" selected>${estado}</option>`
            } else {

                if (inmueble.inmueble_transaccion.id == 1) { //venta
                    if (`${estado}` == "Arrendado") {

                    } else {
                        optionStados += `<option value="${index}">${estado}</option>`
                    }
                } else if (inmueble.inmueble_transaccion.id == 2) { //arriendo
                    if (`${estado}` == "Vendido") {

                    } else {
                        optionStados += `<option value="${index}">${estado}</option>`
                    }
                } else if (inmueble.inmueble_transaccion.id == 3) { //arriendo o venta
                    optionStados += `<option value="${index}">${estado}</option>`
                }


            }

        });


        var comentarios = "<p>No existen comentarios</p>";

        inmueble.comentarios.forEach(function(comentario, index) {

            if (comentario.tipo_comentario == 'normal') {
                comentarios += `<p><small><b>${comentario.updated_at}</b></small>
                <br>
                <b>Comentario ${c++}:</b> ${comentario.comentario}
                </p>`;
            } else {
                comentarios += `<p><small><b>${comentario.updated_at}</b></small>
                <br>
                    <b>Comentario cancelación ${n++}:</b> ${comentario.comentario}
                </p>`
            }
        })

        if (inmueble.comentarios.length == undefined) {
            numCommentarios = 0;
        } else {
            numCommentarios = c + n;
        }

        var nombreEdi = "";
        if (inmueble.nombre_edificio) {
            if (inmueble.nombre_edificio != "") {
                nombreEdi = inmueble.nombre_edificio;
            }
        }

        var inmuebleTemp = `<tr>
    <td style="display:none">${inmueble.fecha_creacion}</td>
    <td>
        <div class="text">
            <p>${inmueble.direccion}
            <p>${nombreEdi}
                <br> ${inmueble.inmueble_ciudad.valor}
                <br> Codigo inmueble: ${inmueble.codigo}
            </p>
        </div>
        <div class="coments">
            <a href="" data-row="1" data-id-inmueble="${inmueble.id}" class="coment">Comentarios (${numCommentarios})</a>
            <div class="hidden comments-hidden">
                <div class="comments">
                    ${comentarios}
                </div>
            </div>
        </div>
        
        ${ (inmueble.id_agendar_cita != 13)
            ? ` <a href="" data-idinmueble="${inmueble.id}" class="btn btn-green reasignar">Reasignar</a>`
            : ``}
        
        <a href="clientes/${inmueble.id}" class="btn eye btn-primary">
            Ver
            <i class="entypo-eye" aria-hidden="true"></i>
        </a>
        
            <a href="whatsapp://send?text=http://www.ciencuadras.com/inmueble/${inmueble.id}" data-action="share/whatsapp/share" class="show-movile btn btn-green">
                <span class="entypo-share"></span>
            </a>

    </td>
    <td class="left">
        <p>
        ${inmueble.user_cliente ?
                `<b>Propietario:</b> ${inmueble.user_cliente.id_tipo_usuario != 9 ? inmueble.user_cliente.nombre : inmueble.user_cliente.nombre + " (persona natural)" } 
            <br>
            <b>Email:</b> ${inmueble.user_cliente.correo_electronico}
            <br>
            <b>Celular:</b> ${inmueble.user_cliente.celular}
            <br>
            <b>Teléfono:</b> ${inmueble.user_cliente.telefono}
            <br>`
                : ``}
            ${inmueble.user_cliente.id_tipo_usuario != 9 ?
            `<br>
            <button class="btn btn-primary editarAsesor">Editar Asesor Inmobilaria</button>` : `<br>`}
            <form method="PUT" action="clientes/${inmueble.id}">
                
                <input type="hidden" name="formname" value="asesorx">
                <input type="hidden" name="id_inmueble" value="${inmueble.id}">
                <div class="asesorInmo" style="display:none">
                    <p>
                        <b>Info Asesor Inmobiliario</b>
                    </p>

                    <div class="row">
                        <div class="col-md-2">
                            <b>Nombre:</b>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="auto_submit_item form-control" name="inicial_nombre_asesor" value="${inmueble.nombre_asesor}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <b>Celular:</b>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="auto_submit_item form-control" name="inicial_telefono_asesor" value="${inmueble.telefono_asesor}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <b>Correo:</b>
                        </div>
                        <div class="col-md-12">
                            <input type="email" class="auto_submit_item form-control" name="inicial_correo_asesor" value="${inmueble.correo_asesor}">
                        </div>
                    </div>
                </div>

            </form>
        </p>
        <div class="row">
            <div>
                <label for="">Estado</label>
                <form action="cambiarEstado">
                    <input type="hidden" name="id_inmueble" value="${inmueble.id}">
                    <div class="paddingTopOnly estados-inm">
                        <select name="estado_inmueble" id="estados" class="form-control estado_inmueble" data-allow-clear="false" data-placeholder="Seleccione estado...">
                            ${optionStados}
                        </select>
                    </div>
                </form>
            </div>
        </div>
        ${inmueble.oportunidades.lenght ?
                `<a href="oportunidades/${inmueble.id}" class="btn btn-primary">Oportunidades</a>`
                :
                ``}
    </td>
    <td class="hidden-sm-down">${inmueble.inmueble_tipo.valor}

         ${ (inmueble.inmueble_tipo.valor == 'Bodega')
        ? `  <br> Area: ${inmueble.area_bodega} m2</td>`
        : (inmueble.inmueble_tipo.valor == 'Lote') 
          ? `<br> Area: ${inmueble.area_lote} m2</td>` : `<br> Area: ${inmueble.area_construida} m2</td>`}

    <td class="center hidden-sm-down">${inmueble.inmueble_transaccion.valor}
        <br> ${inmueble.inmueble_transaccion.id == 1 ? `Valor venta:
        <br>
        <span class="currencyx">${inmueble.precio_venta}</span>`
                : inmueble.inmueble_transaccion.id == 2 ? `Valor arriendo:
        <br>
        <span class="currencyx">${inmueble.canon_arrendamiento}</span>
        ` : `Valor arriendo:
        <br>
        <span class="currencyx">${inmueble.canon_arrendamiento}</span>
        Valor venta:
        <br>
        <span class="currencyx">${inmueble.precio_venta}</span>
        `}
    </td>
    <td class="center estado ${estadosVisual[inmueble.estado]['estado']}" data-id-inmueble="${inmueble.id}">
        <a href="clientes/${inmueble.id}/edit">
            <div class="cont">
                ${inmueble.certificado == 1 && inmueble.estado == 4 ? `<span class="publicado">Publicado</span>` : ``}
                <p>${estadosVisual[inmueble.estado]['mensaje']}</p>
                <img src="${assets}img/${estadosVisual[inmueble.estado]['estado']}.png" alt="">
            </div>
        </a>
    </td>

</tr>`
        return inmuebleTemp;
    }
}



if ($('.tableusuariosXtreme').length > 0) {
    var oTable;
    oTable = $('#table-2').DataTable({
        "stateSave": true,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Spanish.json",
            searchPlaceholder: "Buscar"
        },
        "aoColumnDefs": [
            {
                "bSortable": false,
                "paging": false,
                "orderMulti": false,
                "searchable": false,
                "ordering": false,
                "aTargets": [4]
            }
        ],
        "bLengthChange": false
    });
    $(document).ready(function () {
        var xmlhttp = new XMLHttpRequest();
        var url = "getusers";

        xmlhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var usuarios = JSON.parse(this.responseText);
                montarUsuarios(usuarios);
            } else if (this.status != 200) {}
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    })
    function montarUsuarios(usuarios) {
        if (usuarios.length != 0) {
            var cont = 0;
            var global = 0;

            let html = '';
            usuarios.map(function (usuario) {
                html += templateTable(usuario);
                if (cont == 15) {
                    oTable.rows.add($(html)).draw();
                    html = '';
                    cont = 0;
                    $(".cargando").hide();
                    $(".tableusuariosXtreme").show();
                }
                if (global == usuarios.length - 1) {
                    $(".cargando").hide();
                    $(".tableusuariosXtreme").show();
                    oTable.rows.add($(html)).draw();
                }
                cont++;
                global++;
            });
        } else {
            $(".cargando").hide();
            $(".tableusuariosXtreme").show();
        }

    }

    //************Admin*****************/


    //usuarios

    function templateTable(usuario) {
        return `<tr> <td class='controles left'> <p>${usuario.nombre}</p> </td> <td class='controles left'> <p>${usuario.correo_electronico}</p> </td> <td class='controles hidden-sm-down'> <div style="height: 110px; overflow:hidden;overflow-y: auto;"> ${usuario.zonas.map(zona => `<p>${zona.zona.zona}</p>`).join('')} </div> </td> <td class='controles hidden-sm-down'> <div style="height: 110px; overflow:hidden;overflow-y: auto;text-align: left;"> ${usuario.inmobiliarias.map(inmobiliaria => ` ${inmobiliaria.usuario != null ? `<p>${inmobiliaria.usuario.id} - ${inmobiliaria.usuario.nombre}</p> ` : ''} `).join('')} </div> </td> <td class='center controles '> <div class='cont'> <a href='usuarios/${usuario.id}/edit' class='btn btn-warning'>Editar</a> <a href='eliminar/${usuario.id}' class='btn btn-danger'>Eliminar</a> </div> </td> </tr>`
    }
}

//************Admin*****************/

if ($('.cotizacionesReportes').length > 0) {
    var ctx = [];
    ctx.push(document.getElementById('myChart').getContext('2d'));
    ctx.push(document.getElementById('myChart2').getContext('2d'));
    ctx.push(document.getElementById('myChart3').getContext('2d'));

    var chart = []
    ctx.map(function (ctx) {
        var chartx = new Chart(ctx, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                labels: [],
                datasets: []
            },

            // Configuration options go here
            options: {
                tooltips: {
                    mode: 'label',
                    label: 'mylabel',
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                                if (parseInt(value) >= 1000) {
                                    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                    return '$' + value;
                                }
                            }
                        }
                    }]
                }
            }
        });
        chart.push(chartx);
    })

    moment.locale('es');
    var start = moment().subtract(29, 'days');
    var end = moment();
    start.format('YYYY MMMM DD')
    end.format('YYYY MMMM DD')
    function cb(start, end, this_) {
        $(this_).find('span').html(start.format('YYYY MMMM DD') + ' - ' + end.format('YYYY MMMM DD'));
        var fechas = start.format('YYYY-MM-DD') + '.' + end.format('YYYY-MM-DD');
        requestChart(this_.attributes['data-chart'].value, fechas);
    }
    function requestChart(id, fechas) {
        id = parseInt(id) + 1;
        var xmlhttp = new XMLHttpRequest();
        var url = "reportescoti/" + id + '?fechas=' + fechas;
        var data = "";
        xmlhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                data = JSON.parse(this.responseText);
                chart[id - 1].data.labels = data.labels;
                chart[id - 1].data.datasets = data.datasets;
                chart[id - 1].update();

            } else if (this.status != 200) {
                var error = { "error": xmlhttp.statusText };
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }
    $('.reportrange').daterangepicker({
        "locale": {
            "format": "YYYY/MM/DD",
            "separator": " - ",
            "applyLabel": "Buscar",
            "cancelLabel": "Cancelar",
            "fromLabel": "Desde",
            "toLabel": "Hasta",
            "customRangeLabel": "Custom",
            "daysOfWeek": [
                "Do",
                "Lu",
                "Ma",
                "Mi",
                "Ju",
                "Vi",
                "Sa"
            ],
            "monthNames": [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Deciembre"
            ],
            "firstDay": 1
        },
        startDate: start,
        endDate: end,

        ranges: {
            'Ultimos 7 Dias': [moment().subtract(6, 'days'), moment()],
            'Ultimos 30 Dias': [moment().subtract(29, 'days'), moment()],
            'Este mes': [moment().startOf('month'), moment().endOf('month')],
            'Ultimo Mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    });

    $('.reportrange').on('apply.daterangepicker', function (ev, picker) {
        cb(picker.startDate, picker.endDate, this);
    });
    chart.map(function (chart) {
        Chart.plugins.register({
            afterDraw: function (chart) {
                if (chart.data.datasets.length === 0) {
                    // No data is present
                    var ctx = chart.chart.ctx;
                    var width = chart.chart.width;
                    var height = chart.chart.height
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.font = "16px normal 'Helvetica Nueue'";
                    ctx.fillText('No data to display', width / 2, height / 2);
                    ctx.restore();
                }
            }
        });
    })





}



//************Admin*****************/

if ($('.reportesNormal').length > 0) {
    var ctx = [];
    ctx.push(document.getElementById('myChart').getContext('2d'));
    ctx.push(document.getElementById('myChart2').getContext('2d'));
    ctx.push(document.getElementById('myChart3').getContext('2d'));

    var chart = []
    ctx.map(function (ctx) {
        var chartx = new Chart(ctx, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                labels: [],
                datasets: []
            },

            // Configuration options go here
            options: {
                tooltips: {
                    mode: 'label',
                    label: 'mylabel',
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                                if (parseInt(value) >= 1000) {
                                    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                    return '$' + value;
                                }
                            }
                        }
                    }]
                }
            }
        });
        chart.push(chartx);
    })

    moment.locale('es');
    var start = moment().subtract(29, 'days');
    var end = moment();
    start.format('YYYY MMMM DD')
    end.format('YYYY MMMM DD')
    function cb(start, end, this_) {
        $(this_).find('span').html(start.format('YYYY MMMM DD') + ' - ' + end.format('YYYY MMMM DD'));
        var fechas = start.format('YYYY-MM-DD') + '.' + end.format('YYYY-MM-DD');
        requestChart(this_.attributes['data-chart'].value, fechas);
    }
    function requestChart(id, fechas) {
        id = parseInt(id) + 1;
        var xmlhttp = new XMLHttpRequest();
        var url = "reportesnormal/" + id + '?fechas=' + fechas;
        var data = "";
        xmlhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                data = JSON.parse(this.responseText);
                chart[id - 1].data.labels = data.labels;
                chart[id - 1].data.datasets = data.datasets;
                chart[id - 1].update();

            } else if (this.status != 200) {
                var error = { "error": xmlhttp.statusText };
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }
    $('.reportrange').daterangepicker({
        "locale": {
            "format": "YYYY/MM/DD",
            "separator": " - ",
            "applyLabel": "Buscar",
            "cancelLabel": "Cancelar",
            "fromLabel": "Desde",
            "toLabel": "Hasta",
            "customRangeLabel": "Custom",
            "daysOfWeek": [
                "Do",
                "Lu",
                "Ma",
                "Mi",
                "Ju",
                "Vi",
                "Sa"
            ],
            "monthNames": [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Deciembre"
            ],
            "firstDay": 1
        },
        startDate: start,
        endDate: end,

        ranges: {
            'Ultimos 7 Dias': [moment().subtract(6, 'days'), moment()],
            'Ultimos 30 Dias': [moment().subtract(29, 'days'), moment()],
            'Este mes': [moment().startOf('month'), moment().endOf('month')],
            'Ultimo Mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    });

    $('.reportrange').on('apply.daterangepicker', function (ev, picker) {
        cb(picker.startDate, picker.endDate, this);
    });
    chart.map(function (chart) {
        Chart.plugins.register({
            afterDraw: function (chart) {
                if (chart.data.datasets.length === 0) {
                    // No data is present
                    var ctx = chart.chart.ctx;
                    var width = chart.chart.width;
                    var height = chart.chart.height
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.font = "16px normal 'Helvetica Nueue'";
                    ctx.fillText('No data to display', width / 2, height / 2);
                    ctx.restore();
                }
            }
        });
    })





}
if ($('.tableCotizacionesXtreme').length > 0) {
    var oTable;
    oTable = $('.tableCotizacionesXtreme').DataTable({
        "stateSave": true,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Spanish.json",
            searchPlaceholder: "Buscar"
        },
        "aoColumnDefs": [
            {
                "bSortable": false,
                "paging": false,
                "orderMulti": false,
                "searchable": false,
                "ordering": false,
                "aTargets": [3]
            }
        ],
        "bLengthChange": false
    });
    $(document).ready(function () {
        var xmlhttp = new XMLHttpRequest();
        var url = "getcotizaciones";

        xmlhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var cotizaciones = JSON.parse(this.responseText);
                montarCotizaciones(cotizaciones);
            } else if (this.status != 200) {}
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();


        if ($('[data-toggle="tooltip"]').length > 0) {
            $('[data-toggle="tooltip"]').tooltip()
        }


    })
    function montarCotizaciones(cotizaciones) {
        if (cotizaciones.length != 0) {
            var cont = 0;
            var global = 0;

            let html = '';
            cotizaciones.map(function (cotizacion) {
                html += templateTable(cotizacion);
                if (cont == 15) {
                    oTable.rows.add($(html)).draw();
                    html = '';
                    cont = 0;
                    $(".cargando").hide();
                    $(".tableCotizacionesXtreme").show();
                }
                if (global == cotizaciones.length - 1) {
                    $(".cargando").hide();
                    $(".tableCotizacionesXtreme").show();
                    oTable.rows.add($(html)).draw();
                }
                cont++;
                global++;
            });
        } else {
            $(".cargando").hide();
            $(".tableCotizacionesXtreme").show();
        }

        initComents();

    }

    //************Admin*****************/


    //Cotizacion

    function templateTable(cotizacion) {
        var infocontact = cotizacion.facturacionOtros != null ? JSON.parse(cotizacion.facturacionOtros) : 0;
        var infoAsistencia = cotizacion.contacto_asistencia != null ? JSON.parse(cotizacion.contacto_asistencia) : 0;

        var templateFacturar = `<div class="row">
        <form class="formFacturar" data-id="${cotizacion.id}">
        <div class="col-sm-12">
            <input ${cotizacion.facturacion == 1 ? 'checked' : ''} id="1${cotizacion.id}" checked class="facturarRadio" type="radio" name="facturar" value="1">
            <label for="1${cotizacion.id}"><span class="hidden-sm-down">Factura a</span> inmobiliaria</label>
        </div>
        <div class="col-sm-12">
            <input ${cotizacion.facturacion == 2 ? 'checked' : ''} id="2${cotizacion.id}" class="facturarRadio" type="radio" name="facturar" value="2">
            <label for="2${cotizacion.id}"><span class="hidden-sm-down">Factura a</span> propietario</label>
        </div>
        <div class="col-sm-12">
            <input ${cotizacion.facturacion == 3 ? 'checked' : ''} id="3${cotizacion.id}" class="facturarRadio" type="radio" name="facturar" value="3">
            <label for="3${cotizacion.id}"><span class="hidden-sm-down">Factura a</span> otro</label>
        </div>
    <div class="asesorInmo" style="display:none">
        <div class="row">
            <div class="col-md-12">
                <b>Nombre/ Razón Social</b>
            </div>
            <div class="col-md-12">
                <input type="text" class="form-control" name="facturarOtro_nombre" value="${infocontact != 0 ? infocontact.nombre : ''}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b>Cédula/ Nit</b>
            </div>
            <div class="col-md-12">
                <input type="text" class="form-control" name="facturarOtro_cedula" value="${infocontact != 0 ? infocontact.cedula : ''}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b>Dirección</b>
            </div>
            <div class="col-md-12">
                <input type="text" class="form-control" name="facturarOtro_direccion" value="${infocontact != 0 ? infocontact.direccion : ''}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b>Teléfono</b>
            </div>
            <div class="col-md-12">
                <input type="text" class="form-control" name="facturarOtro_telefono" value="${infocontact != 0 ? infocontact.telefono : ''}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b>Correo electrónico</b>
            </div>
            <div class="col-md-12">
                <input type="email" class="form-control" name="facturarOtro_correo" value="${infocontact != 0 ? infocontact.correo : ''}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button id="GuardarInfo" class="btn btn-info">Guardar</button>
            </div>
        </div>
        </div>
        </form>
    </div>`;
        var templateAsistencia = `<div class="asistenciaContacto"  data-id="${cotizacion.id}">
    <div class="row">
        <div class="col-md-12">
            <b>Nombre contacto</b>
        </div>
        <div class="col-md-12">
            <input type="text" class="form-control" name="infoAistencia_nombre" value="${infoAsistencia != 0 ? infoAsistencia.nombre : ''}">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <b>Teléfono contacto</b>
        </div>
        <div class="col-md-12">
            <input type="text" class="form-control" name="infoAistencia_telefono" value="${infoAsistencia != 0 ? infoAsistencia.telefono : ''}">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <b>Correo electrónico contacto</b>
        </div>
        <div class="col-md-12">
            <input type="email" class="form-control" name="infoAistencia_correo" value="${infoAsistencia != 0 ? infoAsistencia.correo : ''}">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button id="guardarAsistencia" class="btn btn-info">Enviar<span class="hidden-sm-down"> a Asistencia</span></button>
        </div>
    </div>
</div>`;
        var enviarEncuesta = `<div class="encuesta"  data-id="${cotizacion.id}">
    <div class="row">
        <div class="col-md-12">
            <button id="diligenciarEncuesta" class="btn btn-info">Diligenciar ${cotizacion.encuesta == 1 ? 'Nueva' : ''} <span class="hidden-sm-down">encuesta</span></button>
        </div>
        <div class="col-md-12" style="padding: 1em;">
            <input placeholder="Correo electrónico" style="display:none;max-width: 160px;margin: 0 auto;" type="email" class="form-control" name="correo_cotizacion">
        </div>
        <div class="col-md-12">
            <button id="enviarEncuesta" class="btn btn-info">${cotizacion.encuesta == 1 ? 'Reenviar' : 'Enviar'} <span class="hidden-sm-down">encuesta</span></button>
        </div>
    </div>
</div>`;

        var finalizadoGarantia = `<div class="finalizarServicio"  data-id="${cotizacion.id}">
    <div class="row">
        <div class="col-md-12">
            <button id="finalizarServicio" class="btn btn-info">Finalizar servicio</button>
        </div>
    </div>
</div>`;
        var templateEnvioMail = ``;
        return `<tr> <td class="hidden-sm-down">${cotizacion.created_at}</td> <td> <div class="text"> <p>${cotizacion.codigo}</p> </div> </td> <td class="left hidden-sm-down"> <p>${cotizacion.user_cliente != null ? `<b>Propietario:</b> ${cotizacion.user_cliente.nombre} <br> <b>Email:</b> ${cotizacion.user_cliente.correo_electronico} <br> <b>Celular:</b> ${cotizacion.user_cliente.celular} <br> <b>Teléfono:</b> ${cotizacion.user_cliente.telefono} <br> </p> ` : ''} </td> <td> <form class="modalcomments-form" method="PUT" action="cotizacionesguardarcomentarios/${cotizacion.id}"> <div class="coments"><div class="row"><div class="col-sm-12"> <a href="" data-row="1" data-id-inmueble="${cotizacion.id}" class="coment btn btn-info">Comentarios</a></div></div>  <div class="hidden comments-hidden"> <div class="comments"> ${cotizacion.comentarios == null ? `No hay comentarios` : `cotizacion.comentarios`} </div> </div> </div> </form>${templateFacturar} </td> <td class="center ">${cotizacion.estado.id == 8 ? cotizacion.estado.nombre + templateAsistencia : cotizacion.estado.nombre} ${cotizacion.estado.id == 5 ? finalizadoGarantia : ''}${cotizacion.estado.id == 6 ? enviarEncuesta : ''}</td></tr>`
    }



}
function initComents() {



    $(document).on("click", "#diligenciarEncuesta", function (e) {
        let token = $("input[name=_token]").val();
        var id = $(this).parents(".encuesta").attr("data-id");
        var this_ = this;
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': token
            },
            url: "actualizarcotizacion/" + id,
            type: 'POST',
            data: ({ formname: 'diligenciarEncuesta' }),
            success: function (response) {
                location.href = response;
            }
        });
    })

    $(document).on("click", "#guardarAsistencia", function (e) {
        e.preventDefault();
        var info = {}

        info.nombre = $(this).parents(".asistenciaContacto").find("input[type=text][name=infoAistencia_nombre]").val();
        info.telefono = $(this).parents(".asistenciaContacto").find("input[type=text][name=infoAistencia_telefono]").val();
        info.correo = $(this).parents(".asistenciaContacto").find("input[type=email][name=infoAistencia_correo]").val();
        if (info.nombre == "") {
            $(this).parents(".asistenciaContacto").find("input[type=text][name=infoAistencia_nombre]").css("border", "1px solid red");
        } else if (info.telefono == "") {
            resetCamposx();
            $(this).parents(".asistenciaContacto").find("input[type=text][name=infoAistencia_telefono]").css("border", "1px solid red");
        } else if (info.correo == "") {
            resetCamposx();
            $(this).parents(".asistenciaContacto").find("input[type=email][name=infoAistencia_correo]").css("border", "1px solid red");
        } else if (!validateEmail(info.correo)) {
            $(this).parents(".asistenciaContacto").find("input[type=email][name=infoAistencia_correo]").css("border", "1px solid red");
        } else {
            resetCamposx();
            $("#modaldetalle .flexmodal .modal-forms h3").html("Guardando");
            $("#modaldetalle").stop().fadeIn();
            $("#modaldetalle .btn").hide();
            info = JSON.stringify(info);
            let token = $("input[name=_token]").val();
            var id = $(this).parents(".asistenciaContacto").attr("data-id");
            var this_ = this;
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': token
                },
                url: "actualizarcotizacion/" + id,
                type: 'POST',
                data: ({ formname: 'actualizarInfoAsistencia', infoAsistencia: info }),
                success: function (response) {
                    $(this).attr("disabled", "disabled");
                    $("#modaldetalle .flexmodal .modal-forms h3").html(response);
                    $("#modaldetalle").stop().fadeIn();
                    $("#modaldetalle .btn").show();
                }
            });
        }

    })

    $(document).on("click", "#enviarEncuesta", function (e) {
        $(this).addClass("enviarEncuesta");
        $(this).parents(".encuesta").find("input[name=correo_cotizacion]").slideDown();
    });
    $(document).on("click", ".enviarEncuesta", function (e) {
        var correo = $(this).parents(".encuesta").find("input[name=correo_cotizacion]").val();
        if (correo == "") {
            resetCampos();
            $(this).parents(".encuesta").find("input[type=email][name=correo_cotizacion]").css("border", "1px solid red");
        } else if (!validateEmail(correo)) {
            $(this).parents(".encuesta").find("input[type=email][name=correo_cotizacion]").css("border", "1px solid red");
        } else {
            resetCampos();

            $("#modaldetalle .flexmodal .modal-forms h3").html("Finalizando..");
            $("#modaldetalle").stop().fadeIn();
            $("#modaldetalle .btn").hide();
            let token = $("input[name=_token]").val();
            var id = $(this).parents(".encuesta").attr("data-id");
            var this_ = this;
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': token
                },
                url: "actualizarcotizacion/" + id,
                type: 'POST',
                data: ({ formname: 'enviarEncuesta', correo: correo }),
                success: function (response) {
                    $(this).attr("disabled", "disabled");
                    $("#modaldetalle .flexmodal .modal-forms h3").html(response);
                    $("#modaldetalle .btn").show();

                    $("#modaldetalle").stop().fadeIn();
                }
            });
        }
    });

    $(document).on("click", "#finalizarServicio", function (e) {
        $("#modaldetalle .flexmodal .modal-forms h3").html("Finalizando");
        $("#modaldetalle").stop().fadeIn();
        let token = $("input[name=_token]").val();
        var id = $(this).parents(".finalizarServicio").attr("data-id");
        var this_ = this;
        $("#modaldetalle .btn").hide();
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': token
            },
            url: "actualizarcotizacion/" + id,
            type: 'POST',
            data: ({ formname: 'finalizarServicio' }),
            success: function (response) {
                $(this).attr("disabled", "disabled");
                $("#modaldetalle .flexmodal .modal-forms h3").html(response);
                $("#modaldetalle").stop().fadeIn();
                $("#modaldetalle .btn").show();
            }
        });
    });


    $(document).on("click", "#GuardarInfo", function (e) {
        e.preventDefault();
        var info = {}
        info.nombre = $(this).parents("form").find("input[type=text][name=facturarOtro_nombre]").val();
        info.cedula = $(this).parents("form").find("input[type=text][name=facturarOtro_cedula]").val();
        info.direccion = $(this).parents("form").find("input[type=text][name=facturarOtro_direccion]").val();
        info.telefono = $(this).parents("form").find("input[type=text][name=facturarOtro_telefono]").val();
        info.correo = $(this).parents("form").find("input[type=email][name=facturarOtro_correo]").val();
        if (info.nombre == "") {
            $(this).parents("form").find("input[type=text][name=facturarOtro_nombre]").css("border", "1px solid red");
        } else if (info.cedula == "") {
            resetCampos();
            $(this).parents("form").find("input[type=text][name=facturarOtro_cedula]").css("border", "1px solid red");
        } else if (info.direccion == "") {
            resetCampos();

            $(this).parents("form").find("input[type=text][name=facturarOtro_direccion]").css("border", "1px solid red");
        } else if (info.telefono == "") {
            resetCampos();

            $(this).parents("form").find("input[type=text][name=facturarOtro_telefono]").css("border", "1px solid red");
        } else if (info.correo == "") {
            resetCampos();
            $(this).parents("form").find("input[type=email][name=facturarOtro_correo]").css("border", "1px solid red");
        } else if (!validateEmail(info.correo)) {
            $(this).parents("form").find("input[type=email][name=facturarOtro_correo]").css("border", "1px solid red");
        } else {
            resetCampos();
            $(this).html("Guardando...");
            info = JSON.stringify(info);
            let token = $("input[name=_token]").val();
            var id = $(this).parents("form").attr("data-id");
            var value = $(this).parents("form").find("input[type=radio][name=facturar]:checked").val();
            var this_ = this;
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': token
                },
                url: "actualizarcotizacion/" + id,
                type: 'POST',
                data: ({ formname: 'actualizarInfo', info: info, facturacion: value }),
                success: function (response) {
                    $(this_).removeClass("btn-info");
                    $(this_).addClass("btn-success");
                    $(this_).html("Guardado");
                    setTimeout(() => {
                        $(this_).html("Guardar");
                        $(this_).removeClass("btn-success");
                        $(this_).addClass("btn-info");
                    }, 1000);
                }
            });
        }

    })
    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }
    function resetCampos() {
        $(".formFacturar input[type=text]").css("border", "1px solid #ebebeb");
        $(".formFacturar input[type=email]").css("border", "1px solid #ebebeb");
        $(".encuesta input[type=email]").css("border", "1px solid #ebebeb");

    }
    function resetCamposx() {
        $(".asistenciaContacto input[type=text]").css("border", "1px solid #ebebeb");
        $(".asistenciaContacto input[type=email]").css("border", "1px solid #ebebeb");
    }

    $(document).on("click", "input[type=radio][name=facturar]", function () {
        var value = $(this).val();
        if (value == '3') {
            $(this).parents("form").find(".asesorInmo").stop().slideDown();
        } else {
            $(this).parents("form").find(".asesorInmo").stop().slideUp();
        }
    })
    $(document).on("change", "input[type=radio][name=facturar]", function () {
        var value = $(this).parents("form").find("input[type=radio][name=facturar]:checked").val();
        var id = $(this).parents("form").attr("data-id");
        if (value == '3') {
            $(this).parents("form").find(".asesorInmo").stop().slideDown();
        } else {
            $(this).parents("form").find(".asesorInmo").stop().slideUp();
            let token = $("input[name=_token]").val();
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': token
                },
                url: "actualizarcotizacion/" + id,
                type: 'POST',
                data: ({ formname: 'actualizarStatus', facturacion: value }),
                success: function (response) {}
            });
        }
    })
    $(document).on("click", ".coments .coment", function (e) {
        e.preventDefault();
        var comentarios = $(this).parents(".coments").find(".comments-hidden .comments").clone();
        var id = $(this).attr('data-id-inmueble');
        action = $(this).parents("tr").find(".modalcomments-form").attr("action");
        normal = $(this).parents(".coments").find(".comments-hidden .comments");
        theCoso = comentarios;
        $("#modal-1").find('.inmueble_id').val(id);
        $("#modal-1").find("form").attr("action", action);
        $("#modal-1 .modal-body").html(comentarios[0].innerHTML);
        $("#modal-1").stop().fadeIn();
    })
    $(document).on("click", "#sendCommentsx", function (e) {
        e.preventDefault();
        var $form = $(this).parents('form');
        var data = $form.serialize();
        var method = $form.attr('method');
        $("#modal-1 .modalcomments-form").find("textarea").attr("disabled", "disabled");
        $("#modal-1 .modalcomments-form").find(".guardando").slideDown();
        $("#modal-1 .modalcomments-form").find("textarea").slideUp();
        $("#modal-1 .modalcomments-form").find(".modal-footer").slideUp();

        var date = new Date(Date.now()).toLocaleString();
        var message = $("#modal-1 .modalcomments-form").find("textarea").val();
        normal.html(theCoso[0].innerHTML + "<br> <small> " + date + "</small> Nota: " + message);
        $.ajax({
            url: $form.attr('action'),
            type: method,
            data: data,
            success: function (response) {
                //...
                $("#modal-1 .modalcomments-form").find(".guardando").slideUp();
                $("#modal-1 .modalcomments-form").find(".modal-footer").slideDown();
                $("#modal-1 .modalcomments-form").find("textarea").slideDown();
                $("#modal-1 .modalcomments-form").find("textarea").removeAttr("disabled");
                $("#modal-1 .modalcomments-form").find("textarea").val("");
                $("#modal-1 .modal-body").append('<p> <small> ' + date + '</small><b> Nota: </b>' + response + '</p>');
            }
        });
    })
    $(document).on("click", "#modal-1 .close", function () {
        $("#modal-1").stop().fadeOut();
    })
}
function setTwoNumberDecimal(event) {
}


function NumAndTwoDecimals(e, field) {
    var val = field.value;
    var re = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/g;
    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
    if (re.test(val)) {

    }
    else {
        val = re1.exec(val);
        if (val) {
            field.value = val[0];
        }
        else {
            field.value = "";
        }
    }
}

$(document).on("keyup", ".espaciosContainer input[type=text]", function (e) {
    NumAndTwoDecimals(e, this);
})

/* NEW SECTION JS */
$(document).ready(function(){

    /* PANEL BOX */
    /* if( $(".ccPanel__boxes") ){
        $(".ccIcon__content").on("click", function(){
            $(this).parents(".ccPanel__boxes").css({
                "display": "none"
            });
        });
    } */

    /* QUESTION BTN */
    /* if( $(".ccModal--info").length > 0 ){
        $(".ccBtn--question").on("click", function(e){
            e.preventDefault();
            $(".ccModal--info").addClass("show");
        });
    } */

    /* NAVBAR */
    $(".ccNavBar__top__item").on("click", function(e){
        e.preventDefault();
        $(".ccNavBar__top__item").removeClass("active");
        $(this).addClass("active");
        var dataNav = $(this).data("nav");
        $(".ccNavBar__container").removeClass("active");
        setTimeout(function(){
            $(".ccNavBar__container[data-container='" + dataNav + "']").addClass("active");
        },1);
    });

    var wWindow = $(window).outerWidth();

    if( wWindow > 900 ){
        
    }

    /* MODAL */

    $(".ccModal").on("click", function(e){
        e.preventDefault();
        if( e.target == this ){
            $(this).removeClass("show");
        }
    });

    /* CHARTS */
    
    if( $(".ccCharts").length > 0 ){
        /* PIE */
        var randomScalingFactor = function() {
			return Math.round(Math.random() * 100);
		};
        window.chartColors = {
            red: 'rgb(255, 99, 132)',
            orange: '#F8A813',
            yellow: 'rgb(255, 205, 86)',
            green: '#3BB29E',
            blue: '#2D73B8',
            purple: '#ba79cd',
            grey: 'rgb(201, 203, 207)',
            cyan: '#47C2EE'
        };
        var config = {
            type: 'pie',
            data: {
                datasets: [{
                    data: [
                        randomScalingFactor(),
                        randomScalingFactor(),
                    ],
                    backgroundColor: [
                        window.chartColors.orange,
                        window.chartColors.purple
                    ],
                    label: 'Dataset 1'
                }],
                labels: [
                    'Orange',
                    'Purple'
                ]
            },
            options: {
                responsive: true,
                legend: {
                    position: "bottom"
                }
            }
        };

        var ccPie = $("#ccPie");
        window.myPie = new Chart(ccPie, config);

        /* BAR CHART */
		var barChartData = {
			labels: ['Caribe', 'Andina', 'Pacifica'],
			datasets: [{
				label: 'Dataset 1',
				backgroundColor: [
					window.chartColors.orange,
					window.chartColors.blue,
					window.chartColors.green
				],
				yAxisID: 'y-axis-1',
				data: [
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
				]
			}]
        };
        
        var ctx = document.getElementById('ccBarChart').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                },
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    }],
                }
            }
        });

        /* BAR CHART HORIZONTAL */

		var horizontalBarChartData = {
			labels: ['Sevicio1', 'Sevicio2', 'Sevicio3', 'Sevicio4', 'Sevicio5', 'Sevicio6', 'Sevicio7'],
			datasets: [{
				label: 'Dataset 1',
				backgroundColor: [
					window.chartColors.orange,
					window.chartColors.blue,
                    window.chartColors.green,
                    window.chartColors.purple,
                    window.chartColors.cyan,
                    window.chartColors.orange,
                    window.chartColors.green
				],
				borderWidth: 1,
				data: [
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor()
				]
			}]
		};

        var ctx3 = document.getElementById('ccBarChartH').getContext('2d');
        window.myHorizontalBar = new Chart(ctx3, {
            type: 'horizontalBar',
            data: horizontalBarChartData,
            options: {
                // Elements options apply to all of the options unless overridden in a dataset
                // In this case, we are setting the border of each horizontal bar to be 2px wide
                elements: {
                    rectangle: {
                        borderWidth: 2,
                    }
                },
                responsive: true,
                legend: {
                    position: 'bottom',
                    display: false
                }
            }
        });
    }
});

$(document).on('change', '#tipox', function() {
    var propertyType = $("#tipox option:selected").text();
    if (propertyType == "Casa campestre") {
        $("select[name=inicial_estrato").parent('.col-md-8').parent('.row').hide();
    } else {
        $("select[name=inicial_estrato").parent('.col-md-8').parent('.row').show();
    }
});